import { handleServerError } from "../../../utils/errorUtils";
import apiClient from "../../../Axios/apiClient";
import { baseURL } from "../../../helpers/baseURL";

export const getUsers = async () => {
  try {
    const response = await apiClient.get(baseURL + "/users");
    return { onSuccess: response.data };
  } catch (error) {
    return handleServerError(error);
  }
};

export const getAllNews = async () => {
  try {
    const response = await apiClient.get(baseURL + "/news");
    return { onSuccess: response.data };
  } catch (error) {
    return handleServerError(error);
  }
};

export const addNews = async (newsItem) => {
  try {
    const response = await apiClient.post(
      baseURL + "/news/addNew",
      newsItem
    );
    return { onSuccess: response.data };
  } catch (error) {
    return handleServerError(error);
  }
};

export const deleteNews = async (id) => {
  try {
    const response = await apiClient.delete(
      baseURL + `/news/delete/${id}`
    );
    return { onSuccess: response.data };
  } catch (error) {
    return handleServerError(error);
  }
};

export const getAllEvents = async () => {
  try {
    const response = await apiClient.get(baseURL + "/events");
    return { onSuccess: response.data };
  } catch (error) {
    return handleServerError(error);
  }
};

export const addEvent = async (eventItem) => {
  try {
    const response = await apiClient.post(
      baseURL + "/events/addNew",
      eventItem
    );
    return { onSuccess: response.data };
  } catch (error) {
    return handleServerError(error);
  }
};

export const deleteEvent = async (id) => {
  try {
    const response = await apiClient.delete(
      baseURL + `/events/delete/${id}`
    );
    return { onSuccess: response.data };
  } catch (error) {
    return handleServerError(error);
  }
};
