import React from "react";
import { Card, CardContent, Typography, Box, Button } from "@mui/material";
import { useMediaQuery } from "@mui/material";

export default function NewsCard({ title, description, date, link }) {
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Card
      sx={{
        maxWidth: isMobile ? "90%" : 350, // Smaller max width for both mobile and desktop
        margin: "10px auto", // Reduced margin for a tighter layout
        boxShadow: 2, // Light shadow for a clean look
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: isMobile ? "4px 10px" : "0px 12px", // Reduced padding for both mobile and desktop
      }}
    >
      <CardContent
        sx={{
          textAlign: isMobile ? "center" : "left", // Center text on mobile
          padding: 0, // Remove extra padding inside CardContent
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            fontSize: isMobile ? "0.7rem" : "0.9rem", // Smaller title size for mobile
            marginBottom: "5px", // Reduced space between title and description
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: "text.secondary",
            fontSize: isMobile ? "0.7rem" : "0.75rem", // Smaller description text for mobile
            marginBottom: "5px", // Reduced margin between description and date
          }}
        >
          {description}
        </Typography>
      </CardContent>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row", // Stack items on mobile
          justifyContent: isMobile ? "center" : "space-between", // Center items on mobile
          alignItems: "center",
          padding: 0, // Removed extra padding
          gap: isMobile ? "8px" : "0", // Reduce gap between items for mobile
        }}
      >
        <Typography
          variant="caption"
          sx={{
            color: "text.secondary",
            fontSize: isMobile ? "0.7rem" : "0.8rem", // Further reduce date size for mobile
          }}
        >
          {date}
        </Typography>
        <Button
          variant="text"
          size="small"
          sx={{
            textTransform: "none",
            color: "primary.main",
            fontSize: isMobile ? "0.7rem" : "0.8rem", // Adjust button size for mobile
            padding: isMobile ? "4px 8px" : "5px 10px", // Compact button on mobile
          }}
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          Повече
        </Button>
      </Box>
    </Card>
  );
}
