import React from "react";
import Footer from "../common/Footer";
import DrawerAppBar from "../common/DrawerAppBar";
import { Typography, Box, Link, Button, useMediaQuery } from "@mui/material";

function Shop() {
  // Use the useMediaQuery hook to detect mobile screen size
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <div
      className="shop"
      style={{ height: "100vh", display: "flex", flexDirection: "column" }}
    >
      <DrawerAppBar />
      <div style={{ flex: "auto", overflowY: "auto" }}>
        <Box sx={{ padding: 4 }}>
          {/* Heading */}
          <Typography
            variant={isMobile ? "h5" : "h4"} // Smaller heading on mobile
            sx={{
              fontWeight: "bold",
              marginBottom: 2,
              textAlign: "center",
            }}
          >
            Открийте нашите уникални брандови тениски и суичъри!
          </Typography>

          {/* Body text */}
          <Typography
            variant={isMobile ? "body2" : "body1"} // Smaller text on mobile
            sx={{
              marginBottom: 2,
              lineHeight: 1.6,
              fontSize: isMobile ? "0.9rem" : "1.1rem", // Adjust font size for mobile
            }}
          >
            Имаме интересни и стилни брандови тениски и суичъри, които ще ви
            отличат от останалите. Нашият клуб предлага уникални дрехи, които
            можете да носите, за да покажете подкрепата си към мотористката ни
            общност.
          </Typography>
          <Typography
            variant={isMobile ? "body2" : "body1"} // Smaller text on mobile
            sx={{
              marginBottom: 2,
              lineHeight: 1.6,
              fontSize: isMobile ? "0.9rem" : "1.1rem", // Adjust font size for mobile
            }}
          >
            Чрез всяка поръчка, вие подпомагате развитието на мото обществото и
            благотворителните каузи, които предприемаме.
          </Typography>
          <Typography
            variant={isMobile ? "body2" : "body1"} // Smaller text on mobile
            sx={{
              marginBottom: 3,
              lineHeight: 1.6,
              fontSize: isMobile ? "0.9rem" : "1.1rem", // Adjust font size for mobile
            }}
          >
            Свържете се с нас за цени и специални оферти.
          </Typography>

          {/* Contact information */}
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "center",
              gap: 2,
            }}
          >
            <Link
              href="mailto:shop@archangelsMCC.com"
              underline="hover"
              sx={{ fontSize: isMobile ? "0.9rem" : "1rem" }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{ fontSize: isMobile ? "0.8rem" : "1rem" }}
              >
                Имейл
              </Button>
            </Link>
            <Link
              href="tel:+359888731619"
              underline="hover"
              sx={{ fontSize: isMobile ? "0.9rem" : "1rem" }}
            >
              <Button
                variant="contained"
                color="secondary"
                sx={{ fontSize: isMobile ? "0.8rem" : "1rem" }}
              >
                Телефон
              </Button>
            </Link>
            <Link
              href="https://www.facebook.com/profile.php?id=100064636993891"
              target="_blank"
              underline="hover"
              sx={{ fontSize: isMobile ? "0.9rem" : "1rem" }}
            >
              <Button
                variant="contained"
                color="info"
                sx={{ fontSize: isMobile ? "0.8rem" : "1rem" }}
              >
                Facebook
              </Button>
            </Link>
          </Box>
        </Box>{" "}
      </div>

      <Footer />
    </div>
  );
}

export default Shop;
