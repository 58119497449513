import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

export default function Notification({ props }) {
  const [open, setOpen] = React.useState(props?.open || false);

  // Watch for changes to the `open` prop and sync the internal state
  React.useEffect(() => {
    setOpen(props?.open || false);
  }, [props?.open]);

  // Internal close handler
  const handleClose = (event, reason) => {
    if (reason === "clickaway") return; // Ignore clickaway events
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={handleClose} // Internal handler
    >
      <Alert severity={props?.severity} onClose={handleClose} sx={{ width: "100%" }}>
        {props?.message}
      </Alert>
    </Snackbar>
  );
}
