import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material"; 

export default function Slideshow() {
  const images = [
    "/slideshow/slideshow1.jpg", 
    "/slideshow/slideshow2.jpg", 
    "/slideshow/slideshow3.jpg", 
    "/slideshow/slideshow4.jpg", 
    "/slideshow/slideshow5.jpg", 
  ];

  const [currentImage, setCurrentImage] = useState(0);
  const [fade, setFade] = useState(true);
  const isMobile = useMediaQuery("(max-width:600px)");

  const handleNext = () => {
    setFade(false); 
    setTimeout(() => {
      setCurrentImage((prev) => (prev + 1) % images.length);
      setFade(true);
    }, 500); 
  };

  
  const handlePrev = () => {
    setFade(false); 
    setTimeout(() => {
      setCurrentImage((prev) => (prev - 1 + images.length) % images.length);
      setFade(true); 
    }, 500); 
  };


  useEffect(() => {
    const interval = setInterval(handleNext, 5000); 
    return () => clearInterval(interval); 
    // t
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="slideshow-container"
      style={{
        position: "relative",
        width: "100%",
        height: isMobile ? "fit-content" : "100%",
        overflow: "hidden",
        borderRadius: "16px",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        opacity:"0.7",
      }}
    >
      {/* Images with fade effect */}
      <img
        src={images[currentImage]}
        alt={`Slide ${currentImage + 1}`}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover", // Ensure the image fills the container
          opacity: fade ? "1" : "0", // Fade effect
          transition: "opacity 0.6s ease-in-out", // Smooth fade-in/out
        }}
      />

      {/* Left Arrow */}
      <button
        onClick={handlePrev}
        style={{
          position: "absolute",
          top: "50%",
          left: "10px",
          transform: "translateY(-50%)",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          color: "white",
          border: "none",
          borderRadius: "50%",
          width: isMobile ? "30px" : "40px",
          height: isMobile ? "30px" : "40px",
          cursor: "pointer",
          zIndex: 1, // Ensure the button stays above the image
          fontSize: isMobile ? "20px" : "30px"
        }}
      >
        &#8249;
      </button>

      {/* Right Arrow */}
      <button
        onClick={handleNext}
        style={{
          position: "absolute",
          top: "50%",
          right: "10px",
          transform: "translateY(-50%)",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          color: "white",
          border: "none",
          borderRadius: "50%",
          width: isMobile ? "30px" : "40px",
          height: isMobile ? "30px" : "40px",
          cursor: "pointer",
          zIndex: 1, // Ensure the button stays above the image
          fontSize: isMobile ? "20px" : "30px"
        }}
      >
        &#8250;
      </button>
    </div>
  );
}
