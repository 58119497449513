import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Paper, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { getNews } from "./Services";
import DrawerAppBar from "../common/DrawerAppBar";
import { useMediaQuery } from "@mui/material"; // For responsive design
import Footer from "../common/Footer";
import { Virtuoso } from "react-virtuoso";
import Notification from "../common/Notification";

export default function News() {
  const [error, setError] = useState(null);
  const [newsList, setNewsList] = useState([]);
  const isMobile = useMediaQuery("(max-width: 600px)");

  // Fetching news data
  useEffect(() => {
    const getNewsList = async () => {
      const news = await getNews();
      if (news.onSuccess) {
        setNewsList(news.onSuccess);
      } else {
        console.log(news);
        setError(news);
      }
    };
    getNewsList();
  }, []);

  return (
    <div
      className="Home"
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        overflowX: "hidden", // Avoid horizontal scroll
        overflowY: "hidden", // Prevent vertical overflow in the main container
      }}
    >
      <Notification props={error} />
      <DrawerAppBar />
      <div
        className="content"
        style={{
          flex: "auto", // Take up remaining space after DrawerAppBar
          overflowY: "hidden", // Only vertical scrolling for content area
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          align="center"
          sx={{ fontSize: isMobile ? "1.2rem" : "1.5rem" }}
        >
          Новини
        </Typography>

        {/* Virtuoso Grid with Card Layout */}
        <Virtuoso
          style={{
            height: "calc(100vh - 150px)", // Take up space but leave room for header/footer
            paddingBottom: "20px", // Prevent cutting off the last article
          }}
          totalCount={newsList.length}
          itemContent={(index) => {
            const article = newsList[index];
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={article.id}
                sx={{ padding: "10px" }}
              >
                <Paper
                  sx={{
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <Typography
                    variant="h6"
                    component="h2"
                    gutterBottom
                    sx={{ fontSize: "1rem" }}
                  >
                    {article.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    gutterBottom
                    sx={{ fontSize: "0.85rem" }}
                  >
                    {new Date(article.date).toLocaleDateString()}
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    sx={{ fontSize: "0.9rem" }}
                  >
                    {article.excerpt}
                  </Typography>

                  <Box sx={{ marginTop: "auto" }}>
                    <Link to={article.link} style={{ textDecoration: "none" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ fontSize: "0.8rem" }}
                      >
                        Прочети
                      </Button>
                    </Link>
                  </Box>
                </Paper>
              </Grid>
            );
          }}
        />
      </div>
      <Footer />
    </div>
  );
}
