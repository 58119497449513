import { handleServerError } from "../../utils/errorUtils";
import apiClient from "../../Axios/apiClient";
import { baseURL } from "../../helpers/baseURL";
/*
 * Service to fetch news
 * @param {Object} params - Query parameters to filter news (optional)
 * @returns {Promise} - Axios promise containing the API response
 */
export const getLastNews = async (params = {}) => {
  try {
    const response = await apiClient.get(baseURL + "/news/lastNews", { params });
    return { onSuccess: response.data }; // Assuming API returns the news in response.data
  } catch (error) {
    return handleServerError(error);
    //throw error; // Re-throw the error to handle it in calling component
  }
};

/**
 * Service to fetch a single news item by ID
 * @param {string} id - The ID of the news item
 * @returns {Promise} - Axios promise containing the API response
 */
export const fetchNewsById = async (id) => {
  try {
    const response = await apiClient.get(baseURL + `/news/${id}`);
    return { onSuccess: response.data }; // Assuming API returns the news item in response.data
  } catch (error) {
    return error;
  }
};

/**
 * Service to add a new news item
 * @param {Object} newsItem - The news item to be added
 * @returns {Promise} - Axios promise containing the API response
 */
export const addNews = async (newsItem) => {
  try {
    const response = await apiClient.post("/news", newsItem);
    return { onSuccess: response.data }; // Assuming API returns the created news item
  } catch (error) {
    return error;
  }
};

/**
 * Service to delete a news item by ID
 * @param {string} id - The ID of the news item to be deleted
 * @returns {Promise} - Axios promise containing the API response
 */
export const deleteNews = async (id) => {
  try {
    const response = await apiClient.delete(`/news/${id}`);
    return { onSuccess: response.data }; // Assuming API returns a success message
  } catch (error) {
    return error;
  }
};
