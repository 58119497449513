import React from "react";
import { Route, Routes } from "react-router-dom";
import AboutUs from "./Components/AboutUs/AboutUs";
import Home from "./Components/Home/Home";
import Shop from "./Components/Shop/Shop";
import Friends from "./Components/Friends/Friends";
import Events from "./Components/Events/Events";
import Gallery from "./Components/Gallery/Gallery";
import ErrorBoundary from "./Components/common/ErrorBoundary";
import News from "./Components/News/News";
import Rules from "./Components/Rules/Rules";
import MobilePullToRefresh from "./Components/common/MobilePullToRefresh";

function App() {
  return (
    <div className="App">
      <MobilePullToRefresh>
        <ErrorBoundary>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/news" element={<News />} />
            <Route exact path="/about" element={<AboutUs />} />
            <Route exact path="/shop" element={<Shop />} />
            <Route exact path="/friends" element={<Friends />} />
            <Route exact path="/events" element={<Events />} />
            <Route exact path="/rules" element={<Rules />} />
            <Route exact path="/gallery" element={<Gallery />} />
          </Routes>
        </ErrorBoundary>
      </MobilePullToRefresh>
    </div>
  );
}

export default App;
