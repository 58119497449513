import * as React from "react";
import DrawerAppBar from "../common/DrawerAppBar";
import Footer from "../common/Footer";
import { useEffect, useState } from "react";
import NewsCard from "./NewsCard";
import { getLastNews } from "./Services";
import { useMediaQuery } from "@mui/material";
import Poll from "./Poll";
import Slideshow from "./Slideshow";
import Notification from "../common/Notification";

export default function Home() {
  const [error, setError] = useState(null);
  const [newsList, setNewsList] = useState([]);
  const isMobile = useMediaQuery("(max-width: 600px)");

  useEffect(() => {
    const getNews = async () => {
      const news = await getLastNews();
      if (news.onSuccess) {
        setNewsList(news.onSuccess || []);
      } else {
        setError(news);
      }
    };
    getNews();
  }, []);

  return (
    <div
      className="Home"
      style={{
        height: "100vh",
        display: isMobile ? "flex" : "flex",
        flexDirection: "column",
        overflowX: "hidden",
        overflowY: "hidden",
      }}
    >
      <Notification props={error} />
      <DrawerAppBar />
      <div
        className="content-wrapper"
        style={{
          flex: "auto",
          display: "flex",
          flexDirection: isMobile ? "column" : "row", 
          overflowY: isMobile ? "auto" : "auto", 
          overflowX: "hidden", 
          width: "100%",
        }}
      >
        <div
          className="other-content-container"
          style={{
            flex: isMobile ? "none" : "0 0 70%", // Adjust width for mobile and desktop
            overflowY: "hidden", // Only vertical scroll on mobile
            minHeight: isMobile ? "fit-content" : "fit-content", // Ensuring content stays above the footer
            backgroundColor: "#ffffff", // White background for the other content
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Light shadow to make the container stand out
            borderRadius: "8px", // Rounded corners for modern design
            display: "flex",
            flexDirection: "column",
            order: isMobile ? -1 : 0, // Make the slideshow appear first on mobile
            margin: "1px",
          }}
        >
          <div
            className="slideshow-container"
            style={{ margin: "10px", height: "90%" }}
          >
            <Slideshow />
          </div>
        </div>

        <div
          className="wrapper"
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "auto",
            zIndex: "10",
          }}
        >
          <div
            className="news-list-container"
            style={{
              flex: isMobile ? "auto" : "auto", // 30% width on desktop, full width on mobile
              backgroundColor: "#ffffff",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
              overflowY: isMobile ? "visible" : "auto", // Independent scroll for desktop
              maxHeight: isMobile ? "none" : "calc(100vh - 120px)", // Limit height on desktop
              width: "100%", // Prevent horizontal overflow
            }}
          >
            <h5
              style={{
                position: isMobile ? "relative" : "sticky", // Sticky only on desktop
                top: "5px",
                backgroundColor: "#FF8C00",
                zIndex: 1,
                margin: "0",
                fontSize: "1.5rem",
                fontWeight: "600",
                color: "#ffffff",
                textAlign: "center",
                borderRadius: isMobile ? "8px" : "8px 8px 0 0", // Rounded top corners
              }}
            >
              Последни новини
            </h5>
            {newsList.map((news) => (
              <NewsCard
                key={news.id}
                title={news.title}
                description={news.description}
                link={news.link}
                date={news.date}
              />
            ))}
          </div>
          <div
            className="poll-container"
            style={{              
              paddingBottom: isMobile ? "100px" : "0px",
            }}
          >
            <Poll />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
