import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from "react";
import {
  Typography,
  TextField,
  IconButton,
  Grid,
  useMediaQuery,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { Virtuoso } from "react-virtuoso";

// Custom comparison for React.memo
const areEqual = (prevProps, nextProps) => {
  return (
    JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data) &&
    prevProps.header === nextProps.header
  );
};

const AdminRenderContent = React.memo(
  ({
    data,
    header,
    onSave,
    onDelete,
    enableEditing = true,
    enableDeleting = true,
  }) => {
    const [editingItemId, setEditingItemId] = useState(null);
    const [editingField, setEditingField] = useState(null);
    const fieldRefs = useRef({});
    const isMobile = useMediaQuery("(max-width:600px)");

    const fields = useMemo(() => {
      return data.length > 0 ? Object.keys(data[0]) : [];
    }, [data]);

    const [editedValues, setEditedValues] = useState({});

    useEffect(() => {
      if (editingItemId && editingField) {
        const fieldRef = fieldRefs.current[`${editingItemId}-${editingField}`];
        if (fieldRef) {
          fieldRef.focus();
        }
      }
    }, [editingItemId, editingField]);

    const handleEditField = useCallback((field, itemId) => {
      setEditingField(field);
      setEditingItemId(itemId);
    }, []);

    const handleCancelEdit = useCallback(() => {
      setEditingItemId(null);
      setEditingField(null);
    }, []);

    const handleSave = useCallback(() => {
      const editingItem = data.find((item) => item.id === editingItemId);
      if (editingItem) {
        const updatedItem = {
          ...editingItem,
          ...editedValues[editingItemId],
        };
        onSave(updatedItem);
        handleCancelEdit();
      }
    }, [data, editingItemId, editedValues, onSave, handleCancelEdit]);

    const handleFieldChange = useCallback(
      (field, value) => {
        setEditedValues((prevState) => ({
          ...prevState,
          [editingItemId]: {
            ...prevState[editingItemId],
            [field]: value,
          },
        }));
      },
      [editingItemId]
    );

    // Row rendering logic
    const renderRow = useCallback(
      (index) => {
        const item = data[index];
        return (
          <Grid
            container
            spacing={1}
            alignItems="center"
            key={item.id}
            sx={{
              padding: "4px 8px",
              borderBottom: "1px solid #ddd",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            {editingItemId === item.id ? (
              <Grid container spacing={1} alignItems="center">
                {fields?.map((field) => (
                  <Grid
                    key={field}
                    item
                    xs={12}
                    sm={Math.floor(12 / fields.length)}
                    sx={{
                      display: "flex",
                      flexDirection: isMobile ? "row" : "column",
                      alignItems: isMobile ? "center" : "flex-start",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "0.8rem",
                        marginRight: isMobile ? 2 : 0,
                        flexShrink: 0,
                      }}
                    >
                      {field.charAt(0).toUpperCase() + field.slice(1)}:
                    </Typography>
                    <TextField
                      value={
                        editedValues[editingItemId]?.[field] || item[field]
                      }
                      onChange={(e) => handleFieldChange(field, e.target.value)}
                      fullWidth
                      size="small"
                      multiline
                      minRows={3}
                      maxRows={3}
                      onBlur={() => setEditingField(null)}
                      disabled={field === "id"}
                      inputRef={(ref) => {
                        fieldRefs.current[`${editingItemId}-${editingField}`] =
                          ref;
                      }}
                      sx={{
                        fontSize: "0.5rem",
                        width: "100%",
                        height: "auto",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxHeight: "100px",
                      }}
                    />
                  </Grid>
                ))}
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: isMobile ? "space-between" : "flex-end",
                    gap: 0.5,
                    marginTop: isMobile ? 1 : 0,
                  }}
                >
                  {enableEditing && (
                    <IconButton
                      onClick={handleSave}
                      color="primary"
                      sx={{ fontSize: "0.8rem", padding: "4px" }}
                    >
                      <SaveIcon fontSize="small" />
                    </IconButton>
                  )}
                  <IconButton
                    onClick={handleCancelEdit}
                    color="secondary"
                    sx={{ fontSize: "0.8rem", padding: "4px" }}
                  >
                    <CancelIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={1} alignItems="center">
                {fields?.map((field) => (
                  <Grid
                    key={field}
                    item
                    xs={12}
                    sm={Math.floor(12 / fields.length)}
                    sx={{
                      display: "flex",
                      flexDirection: isMobile ? "row" : "column",
                      alignItems: isMobile ? "center" : "flex-start",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "0.8rem",
                        marginRight: isMobile ? 2 : 0,
                        flexShrink: 0,
                      }}
                    >
                      {field.charAt(0).toUpperCase() + field.slice(1)}:
                    </Typography>
                    <Typography
                      noWrap
                      onClick={() =>
                        field !== "id" &&
                        enableEditing &&
                        handleEditField(field, item.id)
                      }
                      sx={{
                        cursor:
                          field !== "id" && enableEditing
                            ? "pointer"
                            : "default",
                        fontSize: "0.8rem",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        width: "100%",
                        maxHeight: "50px",
                      }}
                    >
                      {item[field]}
                    </Typography>
                  </Grid>
                ))}
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: isMobile ? "space-between" : "flex-end",
                    gap: 0.5,
                    marginTop: isMobile ? 1 : 0,
                  }}
                >
                  {enableEditing && (
                    <IconButton
                      onClick={() => {
                        setEditingItemId(item.id);
                        setEditingField(null);
                      }}
                      color="primary"
                      sx={{ fontSize: "0.8rem", padding: "4px" }}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  )}
                  {enableDeleting && (
                    <IconButton
                      onClick={() => onDelete(item.id)}
                      color="secondary"
                      sx={{ fontSize: "0.8rem", padding: "4px" }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        );
      },
      [
        data,
        editingItemId,
        editingField,
        fields,
        handleCancelEdit,
        handleFieldChange,
        handleSave,
        isMobile,
        editedValues,
        enableDeleting,
        enableEditing,
        onDelete,
        handleEditField,
      ]
    );

    return (
      <div
        className="content-page"
        style={{
          display: "flex",
          flexDirection: "column",
          flex: "1",
          height: "100vh",
        }}
      >
        {header && (
          <Typography
            variant="h6"
            sx={{
              marginBottom: 2,
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            {header}
          </Typography>
        )}
        <div className="content" style={{ height: "100%" }}>
          <Virtuoso
            data={data}
            itemContent={(index) => renderRow(index)}
            style={{ height: "100%", width: "100%" }}
          />
        </div>
      </div>
    );
  },
  areEqual
);

export default AdminRenderContent;
