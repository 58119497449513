import React, { useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import CircularProgress from "@mui/material/CircularProgress";

// Helper to detect if the device is mobile
const isMobileDevice = () => /Mobi|Android|iPhone|iPad/i.test(navigator.userAgent);

export default function MobilePullToRefresh({ onRefresh, children }) {
  const [isMobile, setIsMobile] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [touchStart, setTouchStart] = useState(0);
  const [dragDistance, setDragDistance] = useState(0);
  const [isPulling, setIsPulling] = useState(false); // Track if the user is actively pulling to refresh

  const [styles, api] = useSpring(() => ({
    y: 0,
    opacity: 0,
    config: { tension: 200, friction: 20 },
  }));

  useEffect(() => {
    setIsMobile(isMobileDevice());
  }, []);

  // Handle touch start
  const handleTouchStart = (e) => {
    // Only start gesture if at the top 1/4th of the page
    const topQuarter = window.innerHeight / 4;
    if (!isMobile || window.scrollY > 0 || e.touches[0].clientY > topQuarter) return;

    // Track touch start position
    setTouchStart(e.touches[0].clientY);
    setIsPulling(true); // Mark that pulling gesture has started
    e.preventDefault(); // Prevent default scroll behavior while dragging
  };

  // Handle touch move
  const handleTouchMove = (e) => {
    if (!isMobile || touchStart === 0 || !isPulling) return;

    const currentY = e.touches[0].clientY;
    const distance = currentY - touchStart;

    if (distance > 0) {
      setDragDistance(distance);
      api.start({
        y: Math.min(distance / 2, 80), // Smoothly animate up to 80px
        opacity: Math.min(distance / 80, 1), // Gradually fade in the indicator
      });
    }
  };

  // Handle touch end
  const handleTouchEnd = () => {
    if (!isPulling) return;

    // Allow scroll after touch end
    if (dragDistance > 60) {
      // Trigger refresh if drag distance exceeds threshold
      setIsRefreshing(true);
      api.start({ y: 50, opacity: 1 }); // Lock position during refresh

      if (onRefresh) {
        // Execute the custom refresh function (this could trigger a data update)
        onRefresh().finally(() => {
          setIsRefreshing(false);
          api.start({ y: 0, opacity: 0 }); // Reset position and hide indicator
        });
      } else {
        // No custom onRefresh, force a page reload
        window.location.reload(); // This will reload the page immediately
      }
    } else {
      // Reset position and hide indicator if not enough drag distance
      api.start({ y: 0, opacity: 0 });
    }

    // Reset touch tracking
    setTouchStart(0);
    setDragDistance(0);
    setIsPulling(false); // End pulling gesture
  };

  return (
    <div
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      style={{
        touchAction: "none", // Disable touch-action to avoid interfering with default behavior
        overflow: "hidden",
        position: "relative",
      }}
    >
      {/* Refresh indicator */}
      <animated.div
        style={{
          opacity: styles.opacity,
          position: "absolute",
          top: 0,
          left: "50%",
          transform: styles.y.to((y) => `translate(-50%, ${y}px)`),
          zIndex: 9999, // Set a high z-index to ensure it appears on top
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isRefreshing ? (
          <CircularProgress size={50} color="primary" /> // Increased size of the spinner
        ) : (
          <CircularProgress size={50} color="inherit" /> // Increased size of the spinner
        )}
      </animated.div>

      {/* Main content */}
      <div>{children}</div>
    </div>
  );
}
