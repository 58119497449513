import React, { useState, useEffect } from "react";
import {
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Typography,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import UserProfileDialog from "./UserProfileDialog";
import AdminPanelDialog from "./Admin/AdminPanelDialog";
import useAuthStore from "../../store/authStore";

export default function UserLoginMenu({ onClick }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isProfileDialogOpen, setIsProfileDialogOpen] = useState(false);
  const [isAdminDialogOpen, setIsAdminDialogOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");
  const { loggedIn, user, logout } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (loggedIn) {
      setAnchorEl(null);
    }
  }, [loggedIn]);

  const handleMenuClick = (event) => {
    event.stopPropagation();
    if (loggedIn) {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    setIsProfileDialogOpen(true);
    handleMenuClose();
  };

  const handleProfileDialogClose = () => {
    setIsProfileDialogOpen(false);
  };

  const handleAdminClick = () => {
    setIsAdminDialogOpen(true);
    handleMenuClose();
  };

  const handleAdminDialogClose = () => {
    setIsAdminDialogOpen(false);
  };

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  return (
    <div style={{ margin: isMobile ? "10px" : "20px" }}>
      {!loggedIn ? (
        <Button
          variant="outlined"
          color="primary"
          onClick={onClick}
          sx={{
            color: "white",
            borderColor: "white",
            borderRadius: "10px",
            textTransform: "none",
            height: "25px",
            marginRight: isMobile ? "0px" : "20px",
            "&:hover": {
              borderColor: "red",
            },
          }}
        >
          Вход
        </Button>
      ) : (
        <div>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleMenuClick}
            sx={{
              color: "white",
              borderColor: "white",
              borderRadius: "10px",
              textTransform: "none",
              height: "25px",
              marginRight: "10px",
              "&:hover": {
                borderColor: "red",
              },
            }}
          >
            {user.username || user.name || "Потребител"}
          </Button>
          <Popper
            open={Boolean(anchorEl) && loggedIn}
            anchorEl={anchorEl}
            transition
            placement={isMobile ? "right-start" : "bottom-start"}
            style={{ zIndex: 1300 }}
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: "top left" }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleMenuClose}>
                    <MenuList>
                      <MenuItem onClick={handleProfileClick}>
                        <Typography variant="body1">Профил</Typography>
                      </MenuItem>
                      {user?.role === "admin" && (
                        <MenuItem onClick={handleAdminClick}>
                          <Typography variant="body1">Admin Panel</Typography>
                        </MenuItem>
                      )}
                      <MenuItem onClick={handleLogout}>
                        <Typography variant="body1">Излез</Typography>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      )}
      <UserProfileDialog
        open={isProfileDialogOpen}
        onClose={handleProfileDialogClose}
        user={user}
      />
      <AdminPanelDialog
        open={isAdminDialogOpen}
        onClose={handleAdminDialogClose}
        user={user}
      />
    </div>
  );
}
