import axios from "axios";
import useAuthStore from "../store/authStore"; // Authentication store
import { getBaseURL } from "../utils/baseURL"; // Base URL utility

const apiClient = axios.create({
  baseURL: getBaseURL(), // Get base URL once at the beginning
  withCredentials: true, // Ensures cookies (refresh token) are sent with requests
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

// Store for handling simultaneous requests during token refresh
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, accessToken = null) => {
  failedQueue.forEach((prom) => {
    if (accessToken) {
      prom.resolve(accessToken);
    } else {
      prom.reject(error);
    }
  });
  failedQueue = [];
};

// Request Interceptor: Add the proper access token for regular or Facebook-specific requests
apiClient.interceptors.request.use(
  async (config) => {
    const { accessToken, facebookAccessToken } = useAuthStore.getState(); // Safe access to Zustand store

    // If access token is available and it's not a Facebook-specific request
    if (accessToken && !config.headers.Authorization && !config.url.includes("facebook-endpoint")) {
      //console.log("🔑 Adding JWT Access Token for request");
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    // If Facebook access token is available and this is a Facebook-specific request
    if (facebookAccessToken && config.url.includes("facebook-endpoint")) {
      //console.log("🔑 Adding Facebook Access Token for request");
      config.headers.Authorization = `Bearer ${facebookAccessToken}`;
    }

    return config;
  },
  (error) => {
    //console.error("❌ Request error: ", error.message);
    return Promise.reject(error);
  }
);

// Response Interceptor: Handle Token Expiry & Refresh
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Token expiry handling (401 error)
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      // If token is being refreshed, add this request to the queue
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({
            resolve: (accessToken) => {
              originalRequest.headers.Authorization = `Bearer ${accessToken}`;
              resolve(apiClient(originalRequest));
            },
            reject: reject,
          });
        });
      }

      isRefreshing = true;

      try {
        //console.log("🔄 Access token expired, refreshing...");
        const { data } = await axios.post(
          `${getBaseURL()}/refresh`, 
          {}, 
          { withCredentials: true } // Ensures the cookie is sent
        );

        //console.log("✅ Token refreshed, retrying request...");
        useAuthStore.getState().setAccessToken(data.accessToken);
        originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;

        // Process all the requests that failed while the token was being refreshed
        processQueue(null, data.accessToken);

        return apiClient(originalRequest);
      } catch (refreshError) {
        //console.error("❌ Refresh token failed, logging out...");
        useAuthStore.getState().logout(); // Clears user state
        processQueue(refreshError, null); // Reject all failed requests
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    // Handle all other types of errors
    //console.error("❌ Response error: ", error.response?.data || error.message);
    return Promise.reject(error);
  }
);

export default apiClient;
