import * as React from "react";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "@mui/material"; // For responsive design
const { version } = require('../../../package.json');

function Copyright() {
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <Typography
      variant="body1"
      color="text.secondary"
      style={{
        position: isMobile ? "fixed" : "relative",
        bottom:"0",
        width: "100%",
        textAlign: "center",
        fontSize: isMobile ? "0.7rem" : "0.9rem", // Smaller description text for mobile
        backgroundColor:"transparent",
        color:"grey"
      }}
    > {`v${version} Copyright © МотоКлуб Архангели ${new Date().getFullYear()}. All rights reserved.`}
    </Typography>
  );
}

export default function Footer() {
  return (
    <div className="footer-container">
      <Copyright />
    </div>
  );
}
