import { handleServerError } from "../../utils/errorUtils";
import apiClient from "../../Axios/apiClient";
import { baseURL } from "../../helpers/baseURL";
/**
 * Registers a new user.
 * @param {Object} userData - The user registration data.
 * @param {string} userData.username - The username of the user.
 * @param {string} userData.email - The email of the user.
 * @param {string} userData.password - The password of the user.
 * @returns {Promise<Object>} - The response data from the server.
 */
export async function registerUser(userData) {
  try {
    const response = await apiClient.post(baseURL + "/users/register", userData, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    // Assuming the API responds with the user data or a success message
    return { onSuccess: response.data }; // Assuming API returns the news in response.data
  } catch (error) {
    // Handle errors appropriately
    if (error.response) {
      return handleServerError(error);
    }
  }
}

export async function loginUser(credentials) {
  try {
    const response = await apiClient.post(baseURL + "/users/login", credentials, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    // Log the response to verify it's correct
    console.log("Normal Login Response:", response);

    // Assuming the API responds with the token and success message
    return { onSuccess: response.data }; // Assuming API returns the news in response.data
  } catch (error) {
    console.error("Error during normal login:", error);
    return handleServerError(error);
  }
}



export async function facebookLoginService(facebookId, name, email) {
  try {
    // Send the credentials (Facebook ID, name, email) to the backend
    const response = await apiClient.post(
      baseURL + "/backend/users/login", 
      { facebookId, name, email }, 
      {
        headers: {
          "Content-Type": "application/json",
        }
      }
    );
    console.log("Facebook Login Response:", response);

    // Assuming the API responds with user data and token in response.data
    return { onSuccess: response.data }; // Return the success response
  } catch (error) {
    // Handle any errors by passing them to your error handler
    return handleServerError(error);
  }
}
