import React from "react";
import { useState } from "react";
import { TextareaAutosize, Button } from "@mui/material";
import Footer from "../common/Footer";
import AppDrawer from "../common/DrawerAppBar";

function Friends() {
  const generalRules = `Основни протоколни правила
1. Протоколът и Уважението са задължителни, когато общувате с МС клуб или негови членове – безусловното им зачитане и демонстрацията на уважение са основата на живота в братството.
2. Терминът „БРАТ” има специално значение за МС кубовете и техните членове – не наричайте някого по този начин, ако не сте от един клуб или не е заслужил това специално обръщение, защото по този начин вие ставате „гарант” за този човек и казвате на останалите, че могат да му вярват както на вас.
3. Членството в МС клуб се показва с цветна емблема на гърба и/или нашивки отпред на елека. Те, както и облеклото са неприкосновени – никога не пипайте якето и елека от облеклото на клубен член. В този смисъл отнемането на клубните символи е най-големият позор за един клубен член. Това се счита за сериозно неуважение и понякога може да доведе до агресивно информиране на непросветените.
4. Евентуален член може лесно да бъде различен по емблемата на гърба. Има много различни начини, по който клубовете идентифицират евентуалните си членове. Могат да имат щрихи, но без основното лого. Могат да си имат емблема „Евентуален член”. Някой не носят емблема, защото всички членове знаят кои са те. Трябва да се отнасяте към евентуалните членове по същия начин като членовете – с уважение и вежливост. Много клубове се обиждат, когато външен човек използва термина за евентуален член. Да наречете някой по този начин, ако не сте член на клуба, често се счита за неуважително.
5. Цветовете в логото са привилегия на МС клубовете – обикновено членовете на клубове различни от МС не носят емблеми с цветове. Счита се, че цветовете са за заслуги, а не се купуват или раздават на желаещи! Това правило може да ви създаде големи главоболия на много места по света, включително отнемане на клубните ви символи. За да избегнете подобни неприятности трябва да сте по-многобройни, когато носите цветна емблема и не сте МС или да се свържете предварително с местните и да уредите въпроса.
6. Ако членувате в различен от МС клуб и някой от МС клуб поиска да свалите емблемата си – не спорете. Най-добрият отговор е „Няма проблем” и внимателно да я свалите. След това уведомете шефа на клуба си относно случката, за да може да се избегнат бъдещи проблеми. Обикновено ще бъдете помолени веднъж.
7. За дадена територия най-силният МС клуб е доминиращ и определя правилата за всички останали в мото общността. С други думи, ако участвате в организирания мото живот, за всичко, което ви хрумне извън обикновеното каране на мотоциклет, трябва да поискате разрешение или да съгласувате. Разбира се ваше право е да опитате да станете главният МС клуб в района и трябва да сте готови да понесете последствията при успех или неуспех в това начинание!
8. Йерархията в МС клуба осигурява адекватните връзки с външния свят. Всички въпроси към братството трябва да се отнасят по компетентност – например ако искате да научите нещо за даден МС клуб трябва да попитате неговия President, ако пък участвате в клубно пътуване, само Road Capitan-ът може да ви информира за мястото ви в колоната, скоростта и др.
9. Клубната къща е съкровено място за всеки МС клуб. Можете да посетите такава само по покана от член на клуба или при отворен за гости ден, като е хубаво да имате предварителна покана за това или да ви представи близък до клуба човек. В никакъв случай не навлизайте в зоните, обозначени със „САМО ЗА ЧЛЕНОВЕ”, ако не ви придружава някой от тях.
10.  Носенето на чуждо лого в клубната къща на МС клуб е допустимо, когато:
a.      сте получили изрично разрешение за това;
b.      сте поканен като член на друг клуб, за да присъствате на събитие;
11.  Когато попаднете сред МС клубни членове, които не познавате – поздравете ги, както бихте поздравили всеки друг и изчакайте докато не ви подадат ръка за здрависване. Ако разговарят – НЕ ги прекъсвайте, изчакайте да ви видят и демонстрирайте желанието си за контакт, но оставете те да поемат инициативата. НЕ се обиждайте, ако не се здрависате. В повечето случаи те просто искат да научат повече за вас и клуба ви преди да се здрависате.
12.  Ако сте ОФИЦИАЛНО представен пред членове на МС клуб, уверете се дали човекът, който ви представя знае към кой клуб принадлежите и каква позиция имате. При никакви обстоятелства не прекъсвайте, докато ви представят или докато говорят. Изчакайте, докато не приключи представянето ви и вежливо се представете правилно. Представете се с истинското си име – не с прякор. Той може да бъде казан по-късно. Например:
a.      Име Фамилия, Име и Вид на клуба, град / държава, длъжност;
b.      Име Фамилия, длъжност, Име и Вид на клуба;
13.  Ако познавате някой член на МС клуб, не отивайте при него просто ей така и не го прекъсвате, докато е с друг член. Изчакайте докато ви забележат и НИКОГА не ги докосвайте или прегръщате, сякаш сте приятели. Не протягайте ръка за здрависване, изчакайте те първи да я протегнат. Ако не сте разпознат, продължете си по пътя. Ако се наложи да говорите с глава на МС клуб, правилният начин да го направите е да минете първо през друг главен или друг член.
14.  Ако някой познавате член на МС клуб, не споменавайте името му, прякорът и клубното име навсякъде, сякаш сте големи приятели (дори и да е така). Често това се приема за голямо неуважение към целия клуб.
15.  Бъдете внимателни, когато говорите на публично място за МС клуб или негови членове – те не винаги носят отличителните си знаци, още повече техните симпатизанти. Казаното от вас може многократно да се промени при предаването от уста на уста и историята съвсем да е извадена от смисъла, който сте вложили, когато попадне в ушите на ръководството на клуба.
16.  Ако по някаква причина имате да кажете нещо, докато сте на публично място, относно МС клуб, дръпнете събеседника си настрана и кажете САМО онова, което имате да казвате. Кажете, колкото се може по-малко, така че някой да не дочуе и съответно да не разбере за какво си говорите.
17.  Всичко, казано между членове на МС клуб следва да остане в рамките на клуба. Ако коментари, дори такива, казани на шега излязат извън тези граници, проблемите могат да започнат. Нещо повече – това може да породи раждането на слухове, които на свой ред да предизвикат проблеми не само за съответния клуб, но и за други такива в или извън областта.
18.  Никога, ама никога не лъжете. Може да откажете да отговорите на въпрос по учтив начин и да посочите човек, който може да отговори. Пригответе се да отговаряте на публични въпроси относно клуба. Например:
a.      Ние сме MCC. клуб, а не МС;
b.      Емблемата ни се дава, а не се заслужава.
c.      Всички модели мотори са добре дошли. Или е само за една марка, или клуб със специални изисквания;
d.      Не сме локализиран клуб;
e.      Ние сме неутрален клуб и не носим подкрепящи емблеми на други МС.
f.      Жените мотористи са добре дошли, могат да заемат постове в клуба.
19.  Не предлагайте вътрешно клубна информация. Ако ви поискат публична информация за местния клон, отговорете, ако можете, но започнат ли да питат за броя на членовете ви или националната верига на организацията, за клубната къща и т.н., веднага отнесете въпроса към ръководството на клуба си.
20.  НЕ парадирайте колко е голямо местното или националното членство на вашия клуб.
21.  Не предлагай на обикновените членове на МС клуб линкове или уеб сайтове. По-добре е да ги отнесеш към някой главен в клуба.
22.  Жените на лидерски позиции или носещи емблема на МС клуб са голяма рядкост. Просто е така. Повечето МС клубове биха общували с мъже, особено що се отнася до бизнес. Когато жена ръководи клуб, тя би следвало да присъства на всяка клубна среща. И макар да няма писано правило за това, решението дали ще контактуват директно с жена или не, зависи от насрещния  клуб / филиал.
23.  „Стара Дама” е често употребяван жаргон за жените на членовете на МС клубове. Това не е негативен или пренебрежителен термин. Емблемата „Собственост на” е начина, по който те изразяват подкрепата към приятел или съпруг, който е член на МС клуб.
24.  Член на клуб може, а в повечето случаи, няма да забележи жена ви или приятелката ви, особено, ако е на първа среща. Може да приемете това по-скоро като израз на уважение към вас самите.
25.  Ако искате да придобиете впечатления за живота в мото общността или да им покажете своето уважение, можете да посетите няколко мото мероприятия. Докато не демонстрирате пристрастие и симпатия ще бъдете считан за „Неутрален” и към вас няма да има големи очаквания по отношение на клубния етикет.
26.  Ако решите да се потопите в приключението на клубния живот – започнете с посещение на подкрепящо или отворено за всички мероприятие, където може да се сдобиете с подкрепяща емблема на даден клуб. Носенето на такава емблема показва голямо уважение. След това може да посетите и клубно мероприятие. Това са първите стъпки, които ви дават възможност да опознаете клуба и да представите себе си.
27.  Ако отидете на „ограничено събитие”, направете го от ваше име, за предпочитане без да носите емблеми на друг клуб. Запомнете, носенето на клубна емблема означава и се възприема от всички други, че действате от името на този клуб и следователно каквото и да се случи, клубът е отговорен и ангажиран да понесе последствията.
28.  Посещението на „ограничено събитие” или чужда клубна къща от името на даден клуб е недвусмислено нарушаване на неутралитета и демонстрация на съпричастност. Взимането на страна е знак за всички конкуренти и е нормално за напред те да ви сметнат за съперник.
29.  Някой събития имат знак „Без цветове” – това означава, че никой не може да се яви с клубни символи. Горното важи и за клубовете, които не са МС, въпреки че техните емблеми не се считат за цветни. Свалянето на символите е знак на уважение към другите клубове и политиката. Независимо какъв клуб сте, уважително е да почетете местните правила. Ако не го направите, всички които са почели тези правила може да се обидят жестоко, защото вие не сте го направили. Запомнете, че на такива места „без цветове”, политиката е избрана за всички – няма разлика между МС и всички останали.
30.  Когато присъствате на официални сбирки на клубове, бъдете наясно с местата на местните мотористи. Ако не сте МС клуб най-добре е да не носите клубна емблема без покана.
31.  Внимавайте за отношението и поведението на другите хора, които са с вас (особено, ако някой консумира алкохол) на събития. Вземете мерки, за да избегнете проблемите преди да са се случили. Например, ако някой изглежда прекалено ядосан или шумен и вероятно неуважителен, дръпнете ги настрани или предложете да отидете някъде, докато се успокоят нещата. Също е добре да уведомите някой главен в клуба за ситуацията. И все пак, ако се възникне конфликт, въпреки усилията ви, когато не присъстват по-главни в клуба, постарайте се те да научат възможно най-бързо. Ако няма шеф, тогава ВСИЧКИ членове на клуба, които присъстват трябва да направят опит да дръпнат човека настрани и да решат проблема.
32.  Проблеми, създадени от даден член на клуб в дадено населено място може да повлияе на други клубове из страната.
33.  НЕ пипайте или сядате на мотор на член на клуб, освен ако не сте поканени да го направите. Не очаквайте такава покана.
34.  Не се съмнявайте, че хората от МС общността приемат изключително сериозно клубния етикет! Историята познава редица случаи на физическо обучаване на човек, показал неуважение или лошо отношение.`;
  const clubRules = "";
  const [showClubRules, setShowClubRules] = useState(false);

  return (
    <div
      className="rules"
      style={{ height: "100vh", display: "flex", flexDirection: "column" }}
    >
      <AppDrawer />
      <Button onClick={() => setShowClubRules(!showClubRules)}>
        {showClubRules ? "Клубни правила" : "Общи правила"}
      </Button>
      <TextareaAutosize
        readOnly
        minRows={5}
        maxRows={5}
        value={showClubRules ? clubRules : generalRules}
        style={{
          margin: "30px 20px",
          height: "100%",
          overflow: "auto",
          padding: "10px",
          fontSize: "16px",
          borderRadius: "8px",
          backgroundColor: "#f9f9f9",
          resize: "none",
          border: "1px solid #ffc170",
        }}
      />
      <Footer />
    </div>
  );
}

export default Friends;
