import { handleServerError } from "../../utils/errorUtils";
import apiClient from "../../Axios/apiClient";
import { baseURL } from "../../helpers/baseURL";
/**
 * Service to fetch news
 * @param {Object} params - Query parameters to filter news (optional)
 * @returns {Promise} - Axios promise containing the API response
 */
export const getNews = async (params = {}) => {
  try {
    const response = await apiClient.get(baseURL + "/news", { params });
    return { onSuccess: response.data }; // Assuming API returns the news in response.data
  } catch (error) {
    return handleServerError(error);

  }
};