import { create } from "zustand";
import apiClient from "../Axios/apiClient";

const useAuthStore = create((set, get) => ({
  loggedIn: false,
  user: null,
  accessToken: null, // Main access token (JWT)
  refreshToken: null,
  facebookAccessToken: null, // Separate Facebook access token
  dialogOpen: false,
  mobileOpen: false,

  login: (user, accessToken, refreshToken, facebookAccessToken = null) => {
    set((state) => {
     // console.log("Login called, setting access token and user:", { user, accessToken, refreshToken, facebookAccessToken });
  
      if (state.accessToken) {
        console.warn("Replacing existing access token.");
      }
  
      console.log(`Setting new JWT access token: ${accessToken}`);
      if (facebookAccessToken) {
        console.log(`Setting new Facebook access token: ${facebookAccessToken}`);
      }
  
      return {
        loggedIn: true,
        user,
        accessToken,
        refreshToken,
        facebookAccessToken,
      };
    });
  },
  
  logout: () => {
    console.log("Logging out, clearing tokens...");
    set({
      loggedIn: false,
      user: null,
      accessToken: null,
      refreshToken: null,
      facebookAccessToken: null,
    });
  },

  refreshAccessToken: async () => {
    const { refreshToken } = get();
    if (!refreshToken) {
      console.warn("No refresh token found, logging out...");
      get().logout();
      return;
    }

    try {
      console.log("Attempting to refresh access token...");
      const response = await apiClient.post("/refresh", { refreshToken });
      console.log("Access token refreshed successfully. New token:", response.data.accessToken);
      set({ accessToken: response.data.accessToken });
    } catch (error) {
      console.error("Failed to refresh token, logging out...");
      get().logout();
    }
  },

  initializeAuth: async () => {
    const { refreshAccessToken } = get();

    try {
      console.log("Initializing authentication, checking current session...");
      const response = await apiClient.get("/auth/me");
      console.log("User authenticated, setting user data:", response.data.user);
      set({ loggedIn: true, user: response.data.user });
    } catch (error) {
      console.error("User session expired, attempting to refresh token...");
      await refreshAccessToken();
    }
  },

  openDialog: () => {
    console.log("Opening dialog...");
    set({ dialogOpen: true });
  },
  
  closeDialog: () => {
    console.log("Closing dialog...");
    set({ dialogOpen: false });
  },

  setMobileOpen: (value) => {
    console.log(`Setting mobileOpen to: ${value}`);
    set({ mobileOpen: value });
  },

  setDialogOpen: (value) => {
    console.log(`Setting dialogOpen to: ${value}`);
    set({ dialogOpen: value });
  },
}));

export default useAuthStore;
