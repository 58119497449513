export function handleServerError(error) {
  let notification = {
    open: true,
    severity: "error",
    message: "Появи се грешка, моля опитайте по-късно.",
  };

  // Check if the error has a response (for HTTP errors)
  if (error.response) {
    const { status, data } = error.response;

    // Map HTTP status codes to user-friendly messages
    switch (status) {
      case 400:
        notification.message =
          data?.message || "Лоша заявка, моля проверете данните.";
        break;
      case 401:
        notification.message = "Моля влезте отново.";
        break;
      case 403:
        notification.message = "Забранен достъп.";
        break;
      case 404:
        notification.message = "Ресурсът не е намерен. Моля, опитайте отново.";
        break;
      case 409:
        notification.message =
          "Потребител с това име или мейл вече съществува.";
        break;
      case 500:
        notification.message = "Сървърна грешка, моля опитайте по-късно.";
        break;
      case 503:
        notification.message =
          "Сървърът временно не може да обработи заявката.";
        break;
      default:
        notification.message = data?.message || `Error: ${status}`;
    }
  }
  // If the error doesn't have a response, handle other cases (e.g., network issues)
  else if (error.request) {
    notification.message = "Сървърът е временно недостъпен.";
  }
  // Handle unexpected errors
  else {
    notification.message = error.message || "Появи се грешка.";
  }

  return notification;
}
