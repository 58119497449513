// components/ErrorBoundary.js

import React, { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    // Update state to indicate an error occurred
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to an error reporting service (e.g., Sentry)
    console.error("Error caught by ErrorBoundary: ", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Fallback UI to show when an error occurs
      return (
        <div style={{ textAlign: "center", padding: "50px" }}>
          <h1>Възникна проблем. Моля опитайте по-късно.</h1>
          <p>Докладвайте проблема, чрез съобщение в facebook страницата ни.</p>
        </div>
      );
    }

    // Render the children if no error occurs
    return this.props.children;
  }
}

export default ErrorBoundary;
