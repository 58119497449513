export const validateRegistration = (formData) => {
    const errors = {};
  
    // Username validation
    if (!formData.username.trim()) {
      errors.username = "Въведете потребителско име.";
    }
  
    // Email validation
    if (!formData.email.trim()) {
      errors.email = "Въведете Email.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors.email = "Невалиден email формат.";
    }
  
    // Password validation
    if (!formData.password.trim()) {
      errors.password = "Въведете парола";
    } else if (formData.password.length < 8) {
      errors.password = "Паролата трябва да е минимум 8 символа";
    }
  
    // Confirm password validation
    if (!formData.confirmPassword.trim()) {
      errors.confirmPassword = "Потвърдете паролата.";
    } else if (formData.confirmPassword !== formData.password) {
      errors.confirmPassword = "Паролите не съвпадат.";
    }
  
    return errors;
  };
  
  export const validateLogin = (formData) => {
    const errors = {};

    // Email validation
    if (!formData.email.trim()) {
      errors.email = "Въведете Email.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors.email = "Невалиден email формат.";
    }
  
    // Password validation
    if (!formData.password.trim()) {
      errors.password = "Въведете парола";
    } else if (formData.password.length < 8) {
      errors.password = "Паролата трябва да е минимум 8 символа";
    }
  
    return errors;
  };
  