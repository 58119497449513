import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  IconButton,
} from "@mui/material/";
import CloseIcon from "@mui/icons-material/Close";
import useAuthStore from "../../store/authStore";
import { registerUser, loginUser, facebookLoginService } from "./Services";
import CircularProgress from "../common/CircularProgress";
import Notification from "../common/Notification";
import {
  validateLogin,
  validateRegistration,
} from "../../helpers/RegisterValidation";

const FacebookSDKLoader = ({ appId, onSdkLoad }) => {
  useEffect(() => {
    const loadFacebookSDK = () => {
      if (!window.FB) {
        const script = document.createElement("script");
        script.src = "https://connect.facebook.net/en_US/sdk.js";
        script.async = true;
        script.defer = true;
        script.onload = () => {
          window.FB.init({
            appId: appId,
            cookie: true,
            xfbml: true,
            version: "v16.0",
          });
          if (onSdkLoad) onSdkLoad(window.FB);
        };
        document.body.appendChild(script);
      } else if (onSdkLoad) {
        onSdkLoad(window.FB);
      }
    };

    loadFacebookSDK();
  }, [appId, onSdkLoad]);

  return null;
};

const DialogLogin = ({ open, onClose }) => {
  const [isRegistering, setIsRegistering] = useState(false);
  const [facebookLoading, setFacebookLoading] = useState(false);
  const [fbReady, setFbReady] = useState(false);
  const { login } = useAuthStore();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    setError(null);
    resetForm();
  }, [open, onClose]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
    if (alert) {
      setTimeout(() => {
        setAlert(null);
      }, 5000);
    }
  }, [error, alert]);

  const handleFBLogin = () => {
    if (!window.FB) {
      console.log("Facebook SDK not loaded. Please try again later.");
      return;
    }
    setFacebookLoading(true);
    window.FB.login(
      (response) => {
        if (response.authResponse) {
          const { accessToken } = response.authResponse;
          window.FB.api(
            "/me",
            { fields: "id,name,email", access_token: accessToken },
            async (userInfo) => {
              const { id, name, email } = userInfo;
              try {
                const userData = await facebookLoginService(id, name, email);
                if (userData && userData.onSuccess.user) {
                  login(userData?.onSuccess?.user, userData?.onSuccess?.token);
                  handleClose();
                } else {
                  console.error("User data or user not found:", userData);
                }
              } catch (err) {
                console.error("Error during login:", err);
              } finally {
                setFacebookLoading(false);
              }
            }
          );
        } else {
          setFacebookLoading(false);
        }
      },
      { scope: "public_profile,email" }
    );
  };

  const handleClose = () => {
    onClose();
    setIsRegistering(false);
    setLoading(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const resetForm = () => {
    setFormData({
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
    });
    setErrors({});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = isRegistering
      ? validateRegistration(formData)
      : validateLogin(formData);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setLoading(!loading);
      if (isRegistering) {
        const status = await registerUser(formData);
        if (status.onSuccess) {
          setAlert({
            open: true,
            message:
              status.onSuccess.message + " \nЗарежда се логин прозореца..",
            severity: "success",
          });
          resetForm();
          setTimeout(() => {
            setIsRegistering(!isRegistering);
            setAlert(null);
          }, 4000);
        } else {
          setError(status);
        }
      } else {
        const user = await loginUser(formData);
        if (user.onSuccess) {
          login(user.onSuccess.user, user?.onSuccess?.token);
          setAlert({
            open: true,
            severity: "success",
            message: "Успешен логин",
          });
          setTimeout(() => {
            onClose();
          }, 2000);
        } else {
          setError(user);
        }
      }
      setLoading(false);
    }
  };

  return (
    <>
      <FacebookSDKLoader
        appId="619434890644683"
        onSdkLoad={() => setFbReady(true)}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {isRegistering ? "Регистрация" : "Вход"}
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "black", // You can change the color as needed
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {!isRegistering && (
            <form autoComplete="on" onSubmit={handleSubmit} noValidate>
              <TextField
                autoComplete="on"
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
                fullWidth
                margin="normal"
              />
              <TextField
                autoComplete="on"
                label="Парола"
                name="password"
                type="password"
                value={formData.password}
                onChange={handleChange}
                error={!!errors.password}
                helperText={errors.password}
                fullWidth
                margin="normal"
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Вход
              </Button>
            </form>
          )}
          {isRegistering && (
            <form onSubmit={handleSubmit} noValidate>
              <TextField
                label="Потребител"
                name="username"
                value={formData.username}
                onChange={handleChange}
                error={!!errors.username}
                helperText={errors.username}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Парола"
                name="password"
                type="password"
                value={formData.password}
                onChange={handleChange}
                error={!!errors.password}
                helperText={errors.password}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Потвърди парола"
                name="confirmPassword"
                type="password"
                value={formData.confirmPassword}
                onChange={handleChange}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
                fullWidth
                margin="normal"
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Регистрация
              </Button>
            </form>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "16px",
            gap: "10px",
          }}
        >
          <Notification props={error || alert} />
          {loading && <CircularProgress />}
          {!isRegistering && (
            <Button
              onClick={handleFBLogin}
              variant="contained"
              color="primary"
              fullWidth
              disabled={facebookLoading || !fbReady}
              sx={{
                backgroundColor: "#4267B2",
                "&:hover": { backgroundColor: "#365899" },
                marginLeft: "10px",
                marginRight: "10px",
              }}
            >
              {facebookLoading ? "Влизане с Facebook..." : "Вход с Facebook"}
            </Button>
          )}
          <Button
            onClick={() => {
              setIsRegistering(!isRegistering);
              resetForm();
            }}
            variant="text"
            fullWidth
            disabled={facebookLoading || !fbReady}
            sx={{ marginLeft: "10px", marginRight: "10px" }}
          >
            {isRegistering ? "Отказ" : "Искам да се регистрирам"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogLogin;
