import React from "react";
import DrawerAppBar from "../common/DrawerAppBar";
import Footer from "../common/Footer";
import styled from "styled-components";

// Styled components for layout styling
const AboutUsContainer = styled.div`
  height: 100vh; /* Ensure the container takes up full viewport height */
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  flex: 1;
  padding: 20px;
  margin: 0 auto;
  text-align: justify;
  overflow-y: auto; /* Allow vertical scrolling */
  padding-right: 20px; /* Provide space for the scrollbar */
  box-sizing: border-box;
`;

const Heading = styled.h3`
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
`;

const Paragraph = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #555;
  text-align: justify;
`;

const AboutUs = () => {
  return (
    <AboutUsContainer>
      <DrawerAppBar />
      <ContentContainer>
        <Heading>Относно Нашият Мото Клуб</Heading>
        <Paragraph>
          Добре дошли на официалната страница на Мото Клуб Архангели! Ние не
          сме просто клуб, ние сме братство от райдъри, които споделят страстта
          си към открития път. Вярваме в свободата, която идва с шофирането, в
          приятелството, което се създава на магистралите, и в неописуемата
          връзка между тези, които живеят за усещането на вятъра в косата и
          дълбокия рев под моторите си.
        </Paragraph>
        <Paragraph>
          Нашият стил на каране не е просто да стигнем до дестинацията, той се
          отнася за самото пътуване. Без значение дали сте опитен райдър или
          току-що сте взели първия си мотоциклет, MCC Архангели е мястото,
          където райдъри на всички нива се събират. Организираме редовни
          покани, събития и срещи, за да отпразнуваме общата си любов към
          мотоциклетите. Присъединете се към нас и да обикаляме заедно,
          създавайки спомени, които ще останат завинаги.
        </Paragraph>
        <Paragraph>
          Архангели е повече от клуб, това е общност. Ние сме обединени от
          страстта към две колела, вълнението от шофирането и уважението,
          което имаме един към друг. Нашата разнообразна група от членове донесе
          различни стилове мотоциклети, произходи и истории, но всички споделяме
          нещо общо - любовта към свободата на открития път.
        </Paragraph>
        <Paragraph>
          Готови ли сте да се присъедините към приключението? Проверете нашия
          календар със събития, свържете се със съмишленици райдъри и станете
          част от семейството на MCC Архангели. Нека караме заедно и създадем
          спомени, които ще останат завинаги!
        </Paragraph>
      </ContentContainer>
      <Footer />
    </AboutUsContainer>
  );
};

export default AboutUs;
