import React, { useState } from "react";
import {
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Box,
  Grid,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";

const Poll = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const pollQuestion = "Любима марка ?";
  const pollOptions = ["Kawasaki", "Yamaha", "Honda", "Suzuki", "KTM", "Ducati", "BMW", "Aprilia", "Друга"];
  const isMobile = useMediaQuery("(max-width: 600px)");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = () => {
    if (selectedOption) {
      setSubmitted(true);
    } else {
      alert("Изберете марка!");
    }
  };

  return (
    <Box
      sx={{
        padding: 1, // Reduced padding
        maxWidth: 400,
        margin: "auto",
        textAlign: "center",
      }}
    >
      <Typography variant="h6" sx={{ marginBottom: 1, fontSize: isMobile ? "14px" : "16px" }}>
        {pollQuestion}
      </Typography>
      {!submitted ? (
        <FormControl sx={{ width: "100%" }}>
          <RadioGroup value={selectedOption} onChange={handleOptionChange}>
            <Grid container spacing={0.1}> {/* Reduced vertical spacing */}
              {pollOptions?.map((option, index) => (
                <Grid
                  item
                  xs={4} // Three columns on small screens
                  sm={4} // Three columns on tablets and larger
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center", // Vertically align radio buttons with labels
                    marginBottom: "4px", // Reduced bottom margin for each option
                  }}
                >
                  <FormControlLabel
                    value={option}
                    control={
                      <Radio
                        sx={{
                          padding: "0px", // Reduced padding for radio button
                        }}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          fontSize: isMobile ? "10px" : "12px", // Adjust font size for mobile
                          textAlign: "left", // Align text properly
                        }}
                      >
                        {option}
                      </Typography>
                    }
                    sx={{
                      margin: "0", // Remove extra margin
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </RadioGroup>
          <Box sx={{ display: "flex", justifyContent: "center", marginTop: 1 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              sx={{
                fontSize: isMobile ? "10px" : "12px", // Font size adjustment
                padding: "4px 12px", // Reduced padding for button
              }}
            >
              Гласувай
            </Button>
          </Box>
        </FormControl>
      ) : (
        <Typography variant="h6" color="success.main" sx={{ fontSize: "14px" }}>
          Вашият глас е отразен!
        </Typography>
      )}
    </Box>
  );
};

export default Poll;
