import React from "react";
import Footer from "../common/Footer";
import DrawerAppBar from "../common/DrawerAppBar";


function Gallery() {
  return (
    <div
      className="gallery"
      style={{ height: "100vh", display: "flex", flexDirection: "column" }}
    >
      <DrawerAppBar />
      <div className="content" style={{ flex: "auto" }}>
        <h2>Този модул се обновява, проверете по-късно.</h2>
      </div>
      <Footer />
    </div>
  );
}

export default Gallery;
