export const getBaseURL = () => {
    const ipAddress = "192.168.188.24"; // Use your local network IP address here (replace with your IP)
    
    // Check if the app is running locally on the computer or in a mobile device
    if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
      // Running locally on the computer, use localhost for testing
      return "http://localhost:5000"; // Replace with your backend server address
    } else if (window.location.hostname.includes("192.168.")) {
      // Running on a mobile device, use the local network IP address
      return `http://${ipAddress}:5000`; // Use the actual IP address of your computer
    }
    
    // Default return value (if nothing matches)
    return "http://localhost:5000";
  };