import * as React from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
  Button,
} from "@mui/material/";
import MenuIcon from "@mui/icons-material/Menu";
import headerImg from "../../assets/archangelsPic.jpg";
import headerLogo from "../../assets/headerLogo.png";
import drawerbackground from "../../assets/drawerbackground.jpeg";
import FacebookIcon from "@mui/icons-material/Facebook";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import UserLoginMenu from "../User/UserLoginMenu";
import DialogLogin from "../Login/DialogLogin";
import { useCallback } from "react";
import useAuthStore from "../../store/authStore";

function DrawerAppBar(props) {
  const { window } = props;
  const isMobile = useMediaQuery("(max-width:600px)");
  const { mobileOpen, dialogOpen, user, setMobileOpen, setDialogOpen } =
    useAuthStore();

  const drawerWidth = 240;

  const navItems1 = [
    { name: "Начало", linkName: "/" },
    { name: "Новини", linkName: "/news" },
    { name: "Събития", linkName: "/events" },
    { name: "Галерия", linkName: "/gallery" },
  ];

  const navItems2 = [
    { name: "Приятели", linkName: "/friends" },
    { name: "Артикули", linkName: "/shop" },
    { name: "Правила", linkName: "/rules" },
    { name: "За Нас", linkName: "/about" },
  ];

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDialogOpen = useCallback(
    (event) => {
      event.stopPropagation();
      setDialogOpen(true);
    },
    [setDialogOpen]
  );

  const handleDialogClose = useCallback(() => {
    setDialogOpen(false);
  }, [setDialogOpen]);

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        textAlign: "center",
        backgroundColor: "#121212",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{ overflowY: isMobile ? "auto" : "hidden", overflowX: "hidden" }}
      >
        <Divider />
        <img
          src={drawerbackground}
          alt="drawerImg"
          width="240vw"
          height="100px"
        />
        <List sx={{ textDecoration: "none" }}>
          {navItems1.map((item, index) => (
            <ListItem
              key={index}
              disablePadding
              sx={{ justifyContent: "center" }}
            >
              <Link
                to={item.linkName}
                style={{ textDecoration: "none", color: "white" }}
              >
                <ListItemButton>
                  <ListItemText
                    primary={item.name}
                    sx={{ textAlign: "center" }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
          {navItems2.map((item, index) => (
            <ListItem
              key={index}
              disablePadding
              sx={{ justifyContent: "center" }}
            >
              <Link
                to={item.linkName}
                style={{ textDecoration: "none", color: "white" }}
              >
                <ListItemButton>
                  <ListItemText
                    primary={item.name}
                    sx={{ textAlign: "center" }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
        </List>
      </Box>
      {isMobile && (
        <Box sx={{ paddingBottom: "10px" }}>
          <Typography
            sx={{
              color: "white",
              textAlign: "center",
              marginBottom: "10px",
            }}
          >
            {user ? `Здравей,` : "Здравей, Гост"}
          </Typography>
          <UserLoginMenu onClick={handleDialogOpen} />
        </Box>
      )}
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className="Header">
      <div
        className="social-icons"
        style={{
          display: "flex",
          position: "absolute",
          top: "15px",
          right: isMobile ? "5px" : "5px",
          zIndex: 10,
        }}
      >
        <IconButton
          href="https://www.facebook.com/profile.php?id=100064636993891"
          style={{ color: "blue", padding: 0, margin: "0 2px" }}
        >
          <FacebookIcon style={{ fontSize: "calc(2px + 5vh)" }} />
        </IconButton>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          alignContent: "center",
          height: isMobile ? "calc(80px + 1vh)" : "calc(100px + 1vh)",
        }}
      >
        {" "}
        <img
          src={headerLogo}
          alt="drawerImg"
          style={{
            zIndex: "50",
            position: isMobile ? "absolute" : "absolute",
            width: isMobile ? "calc(150px + 2vh)" : "calc(250px + 3vh)",
            height: isMobile ? "calc(80px + 1vh)" : "calc(100px + 1vh)",
          }}
        />
        <img
          src={headerImg}
          alt="mainImg"
          style={{
            width: "100%",
            height: isMobile ? "calc(80px + 1vh)" : "calc(100px + 1vh)",
          }}
        />
      </div>

      <AppBar
        component="nav"
        color="transparent"
        elevation={0}
        position="absolute"
        sx={{
          top: 0,
          zIndex: 5,
          background: "transparent",
        }}
      >
        <Toolbar
          sx={{
            marginTop: isMobile ? "0px" : "60px",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              display: { xs: "block", sm: "none" },
              color: "white",
              zIndex: 15,
              position: "absolute",
              top: "auto",
              fontSize: "50px",
            }}
          >
            <MenuIcon sx={{ fontSize: "32px" }} />
          </IconButton>
          <Typography
            component="div"
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "block" },
              textAlign: "left",
            }}
          >
            {navItems1.map((item, index) => (
              <Link
                to={item.linkName}
                key={index}
                style={{ height: "fit-content", textDecoration: "none" }}
              >
                <Button
                  key={index}
                  sx={{
                    color: "white",
                    fontSize: "calc(2px + 1.5vh)",
                    fontWeight: "bold",
                  }}
                >
                  {item.name}
                </Button>
              </Link>
            ))}
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navItems2.map((item, index) => (
              <Link
                to={item.linkName}
                key={index}
                style={{ height: "auto", textDecoration: "none" }}
              >
                <Button
                  key={index}
                  sx={{
                    color: "white",
                    fontSize: "calc(2px + 1.5vh)", // Reduced font size for nav items
                    fontWeight: "bold",
                  }}
                >
                  {item.name}
                </Button>
              </Link>
            ))}
          </Box>
        </Toolbar>
      </AppBar>

      {!isMobile && (
        <Box
          sx={{
            position: "absolute",
            top: "0px", // adjust the positioning as needed
            right: "20px", // space for social icons
            zIndex: 10,
          }}
        >
          <UserLoginMenu onClick={handleDialogOpen} />
        </Box>
      )}

      {/* Mobile Drawer */}
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            background: "#121212",
          },
        }}
      >
        {drawer}
      </Drawer>

      <DialogLogin open={dialogOpen} onClose={handleDialogClose} />
    </div>
  );
}

DrawerAppBar.propTypes = {
  window: PropTypes.func,
};

export default DrawerAppBar;
