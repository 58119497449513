import React, { useState, useMemo, useCallback } from "react";
import { TextField, Button, Grid, Typography, Box } from "@mui/material";

export default function CreateContentForm({
  header,
  fields,
  onSave,
  onCancel,
}) {
  const initialFormData = useMemo(
    () =>
      fields.reduce(
        (acc, field) => ({ ...acc, [field.name]: field.defaultValue || "" }),
        {}
      ),
    [fields]
  );

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = useCallback(
    (fieldName) => (event) => {
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: event.target.value,
      }));
    },
    []
  );

  const handleSave = useCallback(() => {
    onSave(formData);
  }, [formData, onSave]);

  return (
    <Box
    sx={{
      padding: 2,
      margin: "0 auto",
      border: "1px solid #ddd",
      borderRadius: "8px",
      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
      position: "relative", // Ensure no overlapping parent issues
    }}
    >
      <Typography variant="h6" sx={{ marginBottom: 2, textAlign: "center" }}>
        {header}
      </Typography>

      <Grid container spacing={2}>
        {fields?.map((field) => (
          <Grid item xs={12} key={field.name}>
            <Typography
              variant="body2"
              sx={{ marginBottom: 1, fontWeight: "bold" }}
            >
              Поле: {field.name} | Тип: {field.type}
              {field.required ? " (Необходимо)" : ""}
            </Typography>
            <TextField
              label={field.label}
              placeholder={field.placeholder || ""}
              value={formData[field.name]}
              onChange={handleChange(field.name)}
              onFocus={(e) => e.target.focus()}
              fullWidth
              size="small"
              multiline={field.type === "textarea"}
              rows={field.type === "textarea" ? 3 : 1}
              type={field.type === "textarea" ? "text" : field.type}
              inputProps={{
                autoComplete: "off",
              }}
            />
          </Grid>
        ))}

        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}
        >
          <Button
            variant="contained"
            color="primary"
            disableRipple
            disableFocusRipple
            onClick={handleSave}
          >
            Запази
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
