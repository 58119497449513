import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import {
  format,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  startOfWeek,
  isSameDay,
  isToday,
  parseISO,
  isValid,
} from "date-fns";
import { bg } from "date-fns/locale";
import DrawerAppBar from "../common/DrawerAppBar";
import Footer from "../common/Footer";
import { getAllEvents } from "./Services";
import Notification from "../common/Notification";

function Events() {
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [selectedDay, setSelectedDay] = useState(null); 
  const [openDialog, setOpenDialog] = useState(false); 
  const [events, setEvents] = useState([]);
  const isMobile = useMediaQuery("(max-width:600px)");
  const [error, setError] = useState(null);

  useEffect(() => {
    const getEvents = async () => {
      const events = await getAllEvents();
      if (events.onSuccess) {
        setEvents(events.onSuccess);
      } else {
        setError(events);
      }
    };
    getEvents();
  }, []);

  const parseEventDate = (eventDateString) => {
    const parsedDate = parseISO(eventDateString);
    return isValid(parsedDate) ? parsedDate : null;
  };

  const handleDayClick = (day) => {
    const validDay = new Date(day);
    if (isValid(validDay)) {
      setSelectedDay(validDay);
      setOpenDialog(true); 
    } else {
      console.error("Invalid date selected", day);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const daysInMonth = eachDayOfInterval({
    start: startOfMonth(dateRange[0]),
    end: endOfMonth(dateRange[0]),
  });

  const startWeek = startOfWeek(startOfMonth(dateRange[0]));

  const getEventsForDay = (day) => {
    const validEvents = events.filter((event) => {
      const eventDate = parseEventDate(event.date);
      return eventDate && isSameDay(eventDate, day); 
    });
    return validEvents;
  };

  const daysBeforeMonthStart = (startDay) => {
    const start = startDay.getDay();
    return start === 0 ? 6 : start - 1; 
  };

  const handleMonthSelect = (monthIndex) => {
    const newDate = new Date(dateRange[0]);
    newDate.setMonth(monthIndex);
    setDateRange([newDate, newDate]);
  };

  const months = [
    "Януари",
    "Февруари",
    "Март",
    "Април",
    "Май",
    "Юни",
    "Юли",
    "Август",
    "Септември",
    "Октомври",
    "Ноември",
    "Декември",
  ];

  return (
    <div
      className="events"
      style={{ height: "100vh", display: "flex", flexDirection: "column" }}
    >
      <Notification props={error} />
      <DrawerAppBar />
      <div
        className="content"
        style={{ position: "relative", flex: "auto", overflowY: "auto" }}
      >
        <Box textAlign="center" mt={5}>
          <Box mt={3}>
            <Typography variant="h5" gutterBottom>
              Събития за {format(dateRange[0], "MMMM yyyy", { locale: bg })}
            </Typography>

<Box
  sx={{
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "10px",
    marginBottom: "20px",
  }}
>
  {months.map((month, index) => (
    <Button
      key={index}
      variant="contained"
      onClick={() => handleMonthSelect(index)}
      sx={{
        backgroundColor: dateRange[0].getMonth() === index ? "#FF7043" : "#3f51b5",
        "&:hover": {
          backgroundColor: "#FF9800"
        },
        textAlign: "center",
        width: "100%", // Make buttons take full width of their container
        maxWidth: "120px", // Set a max width for buttons to avoid them stretching too much
      }}
    >
      {month}
    </Button>
  ))}
</Box>

            {/* Wrapper for desktop to center the calendar */}
            <Box
              sx={{
                width: isMobile ? "100%" : "80%", // Full width for mobile, 80% for desktop
                margin: "0 auto", // Center horizontally
                padding: isMobile ? "0" : "0 10px", // Add padding for desktop view
              }}
            >
              {/* Grid for the calendar days */}
              <Grid
                container
                spacing={1}
                sx={{ flexWrap: "wrap", justifyContent: "center" }}
              >
                {/* Empty days before the start of the month */}
                {Array.from({ length: daysBeforeMonthStart(startWeek) }).map(
                  (_, index) => (
                    <Grid
                      key={`empty-${index}`}
                      item
                      xs={1}
                      sx={{ textAlign: "center" }}
                    />
                  )
                )}

                {/* Days of the month */}
                {daysInMonth.map((day) => {
                  const isEventDay = getEventsForDay(day).length > 0;
                  const isCurrentDay = isToday(day);
                  return (
                    <Grid
                      item
                      key={day.toISOString()}
                      xs={isMobile ? 2 : 1} // Dynamically adjust card size for mobile/desktop
                      sm={isMobile ? 2 : 1}
                      md={isMobile ? 1 : 1}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "5px", // Add some padding between days
                      }}
                    >
                      <Paper
                        elevation={3}
                        sx={{
                          padding: "10px",
                          width: "100%",
                          cursor: "pointer",
                          backgroundColor: isEventDay
                            ? "#FFEB3B"
                            : isCurrentDay
                            ? "#FF7043"
                            : "#FFFFFF",
                          transition: "all 0.3s ease",
                          "&:hover": {
                            backgroundColor: "#FF9800",
                            transform: "scale(1.05)",
                          },
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => handleDayClick(day)}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: "bold", fontSize: "1.1rem" }}
                        >
                          {format(day, "d", { locale: bg })}
                        </Typography>
                      </Paper>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Box>
        </Box>
      </div>
      <Footer />

      {/* Dialog for displaying events */}
      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth>
        <DialogTitle>
          Събития за{" "}
          {selectedDay
            ? format(selectedDay, "d MMMM yyyy", { locale: bg })
            : ""}
        </DialogTitle>
        <DialogContent>
          {selectedDay && getEventsForDay(selectedDay).length > 0 ? (
            getEventsForDay(selectedDay).map((event, index) => (
              <Box key={index} sx={{ marginBottom: "16px" }}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  {event.name}
                </Typography>
                <Typography variant="body2">{event.description}</Typography>
                <Typography variant="body2" sx={{ marginTop: "10px" }}>
                  Допълнителна информация:
                </Typography>
                <Typography variant="body2">{event.link}</Typography>
              </Box>
            ))
          ) : (
            <Typography variant="body2" color="textSecondary">
              Няма събитие за този ден.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Затвори
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Events;
