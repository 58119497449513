import { handleServerError } from "../../utils/errorUtils";
import apiClient from "../../Axios/apiClient";
import { baseURL } from "../../helpers/baseURL";

export const getAllEvents = async () => {
  try {
    const response = await apiClient.get(baseURL + "/events");
    return { onSuccess: response.data };
  } catch (error) {
    return handleServerError(error);
  }
};
