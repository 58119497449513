import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Grid,
  Box,
  Typography,
  Alert,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import SportsMotorsportsSharpIcon from "@mui/icons-material/SportsMotorsportsSharp";
import TwoWheelerRoundedIcon from "@mui/icons-material/TwoWheelerRounded";
import useAuthStore from "../../store/authStore";
import { updateUserProfile } from "./userServices";

const UserProfileDialog = ({ open, onClose }) => {
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const { user, loggedIn, accessToken } = useAuthStore();

  const [isEditing, setIsEditing] = useState({
    name: false,
    nickname: false,
    facebookProfile: false,
    motorcycleBrand: false,
    motorcycleModel: false,
    motorcycleYear: false,
  });

  const [userData, setUserData] = useState(user || {});
  const [originalData, setOriginalData] = useState(user);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertMessage(null);
      setAlertSeverity("success");
    }, 5000);
    return () => clearTimeout(timer);
  }, [alertMessage]);

  useEffect(() => {
    if(user) setUserData(user)
  }, [user]);

  const handleEdit = (field) => {
    setIsEditing((prev) => ({ ...prev, [field]: true }));
  };

  const handleCancelEdit = (field) => {
    setUserData((prev) => ({ ...prev, [field]: originalData[field] }));
    setIsEditing((prev) => ({ ...prev, [field]: false }));
  };

  const handleChange = (field, value) => {
    setUserData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSave = async () => {
    const userDataWithId = { ...userData, id: user.id };

    const updateProfile = async () => {
      console.log(accessToken)
      const response = await updateUserProfile(userDataWithId, accessToken);
      if (response.onSuccess) {
        setAlertMessage("Успешна операция.");
        setOriginalData(userData);
        setIsEditing({
          name: false,
          nickname: false,
          facebookProfile: false,
          motorcycleBrand: false,
          motorcycleModel: false,
          motorcycleYear: false,
        });
      } else {
        setAlertSeverity("error");
        setAlertMessage("Промените не са запазени");
        setUserData({ ...originalData });
      }
    };
    updateProfile();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          position: "static",
          overflow: "hidden",
        },
      }}
    >
      {loggedIn && (
        <>
          <DialogTitle sx={{ position: "relative" }}>
            Моят Профил
            <IconButton
              edge="end"
              color="inherit"
              onClick={onClose}
              aria-label="close"
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ overflowX: "hidden", padding: 3 }}>
            {alertMessage && (
              <Alert severity={alertSeverity}>{alertMessage}</Alert>
            )}
            <Grid container spacing={3} sx={{ flexWrap: "wrap" }}>
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Box
                    sx={{
                      position: "relative",
                      width: "100px",
                      height: "100px",
                      marginBottom: 2,
                      "&:hover .edit-icon": {
                        opacity: 1,
                        visibility: "visible",
                      },
                    }}
                  >
                    <SportsMotorsportsSharpIcon
                      sx={{ width: "100%", height: "100%" }}
                    />
                    <IconButton
                      onClick={() => handleEdit("picture")}
                      aria-label="edit"
                      className="edit-icon"
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        opacity: 0,
                        visibility: "hidden",
                        transition: "opacity 0.3s, visibility 0.3s",
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Box>
                  <Typography variant="h6" sx={{ marginBottom: 1 }}>
                    {userData.name}
                  </Typography>
                </Box>

                <TextField
                  fullWidth
                  label="Име"
                  value={userData.name}
                  onChange={(e) => handleChange("name", e.target.value)}
                  disabled={!isEditing.name || !!user.facebookId} // Disable if user logged in with Facebook
                  InputProps={{
                    endAdornment: (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {isEditing.name &&
                          !user.facebookId && ( // Allow editing only if not logged in with Facebook
                            <>
                              <IconButton
                                onClick={() => handleSave()}
                                aria-label="save"
                              >
                                <CheckIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => handleCancelEdit("name")}
                                aria-label="cancel"
                              >
                                <CloseIcon />
                              </IconButton>
                            </>
                          )}
                        {!isEditing.name && !user.facebookId && (
                          <IconButton
                            onClick={() => handleEdit("name")}
                            aria-label="edit"
                          >
                            <EditIcon />
                          </IconButton>
                        )}
                      </Box>
                    ),
                  }}
                  inputProps={{ maxLength: 50 }}
                />

                <TextField
                  fullWidth
                  label="Псевдоним"
                  value={userData.nickname || ""}
                  onChange={(e) => handleChange("nickname", e.target.value)}
                  disabled={!isEditing.nickname}
                  InputProps={{
                    endAdornment: (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {isEditing.nickname && (
                          <>
                            <IconButton
                              onClick={() => handleSave()}
                              aria-label="save"
                            >
                              <CheckIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => handleCancelEdit("nickname")}
                              aria-label="cancel"
                            >
                              <CloseIcon />
                            </IconButton>
                          </>
                        )}
                        {!isEditing.nickname && (
                          <IconButton
                            onClick={() => handleEdit("nickname")}
                            aria-label="edit"
                          >
                            <EditIcon />
                          </IconButton>
                        )}
                      </Box>
                    ),
                  }}
                  inputProps={{ maxLength: 50 }}
                  sx={{ marginTop: 2 }}
                />

                <TextField
                  fullWidth
                  label="Ранк"
                  value={userData.rank || "Потребител"}
                  disabled
                  sx={{ marginTop: 2 }}
                />

                <TextField
                  fullWidth
                  label="Статус"
                  value={userData.status || "Активен"}
                  disabled
                  sx={{ marginTop: 2 }}
                />

                <TextField
                  fullWidth
                  label="Email"
                  value={userData.email}
                  disabled={true}
                  sx={{ marginTop: 2 }}
                />

                <TextField
                  fullWidth
                  label="Facebook Профил"
                  value={userData.facebookProfile || ""}
                  onChange={(e) =>
                    handleChange("facebookProfile", e.target.value)
                  }
                  disabled={!isEditing.facebookProfile}
                  InputProps={{
                    endAdornment: (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {isEditing.facebookProfile && (
                          <>
                            <IconButton
                              onClick={() => handleSave()}
                              aria-label="save"
                            >
                              <CheckIcon />
                            </IconButton>
                            <IconButton
                              onClick={() =>
                                handleCancelEdit("facebookProfile")
                              }
                              aria-label="cancel"
                            >
                              <CloseIcon />
                            </IconButton>
                          </>
                        )}
                        {!isEditing.facebookProfile && (
                          <IconButton
                            onClick={() => handleEdit("facebookProfile")}
                            aria-label="edit"
                          >
                            <EditIcon />
                          </IconButton>
                        )}
                      </Box>
                    ),
                  }}
                  inputProps={{ maxLength: 50 }}
                  sx={{ marginTop: 2 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Box
                    sx={{
                      position: "relative",
                      width: "100px",
                      height: "100px",
                      marginBottom: 2,
                      "&:hover .edit-icon": {
                        opacity: 1,
                        visibility: "visible",
                      },
                    }}
                  >
                    <TwoWheelerRoundedIcon
                      sx={{ width: "100%", height: "100%" }}
                    />
                    <IconButton
                      onClick={() => handleEdit("motorcyclePicture")}
                      aria-label="edit"
                      className="edit-icon"
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        opacity: 0,
                        visibility: "hidden",
                        transition: "opacity 0.3s, visibility 0.3s",
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Box>
                  <Typography variant="h6" sx={{ marginBottom: 1 }}>
                    Моят мотор
                  </Typography>
                </Box>
                <TextField
                  fullWidth
                  label="Марка"
                  value={userData.motorcycleBrand || ""}
                  onChange={(e) =>
                    handleChange("motorcycleBrand", e.target.value)
                  }
                  disabled={!isEditing.motorcycleBrand}
                  InputProps={{
                    endAdornment: (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {isEditing.motorcycleBrand && (
                          <>
                            <IconButton
                              onClick={() => handleSave()}
                              aria-label="save"
                            >
                              <CheckIcon />
                            </IconButton>
                            <IconButton
                              onClick={() =>
                                handleCancelEdit("motorcycleBrand")
                              }
                              aria-label="cancel"
                            >
                              <CloseIcon />
                            </IconButton>
                          </>
                        )}
                        {!isEditing.motorcycleBrand && (
                          <IconButton
                            onClick={() => handleEdit("motorcycleBrand")}
                            aria-label="edit"
                          >
                            <EditIcon />
                          </IconButton>
                        )}
                      </Box>
                    ),
                  }}
                  inputProps={{ maxLength: 50 }}
                />
                <TextField
                  fullWidth
                  label="Модел"
                  value={userData.motorcycleModel || ""}
                  onChange={(e) =>
                    handleChange("motorcycleModel", e.target.value)
                  }
                  disabled={!isEditing.motorcycleModel}
                  InputProps={{
                    endAdornment: (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {isEditing.motorcycleModel && (
                          <>
                            <IconButton
                              onClick={() => handleSave()}
                              aria-label="save"
                            >
                              <CheckIcon />
                            </IconButton>
                            <IconButton
                              onClick={() =>
                                handleCancelEdit("motorcycleModel")
                              }
                              aria-label="cancel"
                            >
                              <CloseIcon />
                            </IconButton>
                          </>
                        )}
                        {!isEditing.motorcycleModel && (
                          <IconButton
                            onClick={() => handleEdit("motorcycleModel")}
                            aria-label="edit"
                          >
                            <EditIcon />
                          </IconButton>
                        )}
                      </Box>
                    ),
                  }}
                  inputProps={{ maxLength: 50 }}
                  sx={{ marginTop: 2 }}
                />
                <TextField
                  fullWidth
                  label="Година"
                  value={userData.motorcycleYear || ""}
                  onChange={(e) =>
                    handleChange("motorcycleYear", e.target.value)
                  }
                  disabled={!isEditing.motorcycleYear}
                  InputProps={{
                    endAdornment: (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {isEditing.motorcycleYear && (
                          <>
                            <IconButton
                              onClick={() => handleSave()}
                              aria-label="save"
                            >
                              <CheckIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => handleCancelEdit("motorcycleYear")}
                              aria-label="cancel"
                            >
                              <CloseIcon />
                            </IconButton>
                          </>
                        )}
                        {!isEditing.motorcycleYear && (
                          <IconButton
                            onClick={() => handleEdit("motorcycleYear")}
                            aria-label="edit"
                          >
                            <EditIcon />
                          </IconButton>
                        )}
                      </Box>
                    ),
                  }}
                  inputProps={{ maxLength: 50 }}
                  sx={{ marginTop: 2 }}
                />
              </Grid>
            </Grid>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default UserProfileDialog;
