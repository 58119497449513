import { handleServerError } from "../../utils/errorUtils";
import apiClient from "../../Axios/apiClient";
import { baseURL } from "../../helpers/baseURL";

export const updateUserProfile = async (userData, token) => {
  try {
    const response = await apiClient.put(baseURL + "/users/update-profile", userData, {
      headers: {
        Authorization: `Bearer ${token}`, // Ensure token is passed here
      },
    });
    return { onSuccess: response.data };
  } catch (error) {
    return handleServerError(error);
  }
};
