import React from "react";
import maniaLogo from "../../assets/maniaLogo.jpg";
import { List, Typography, useMediaQuery } from "@mui/material";
import Footer from "../common/Footer";
import AppDrawer from "../common/DrawerAppBar";

function Friends() {
  const isMobile = useMediaQuery("(max-width:600px)");

  const blockStyle = {
    height: isMobile ? "15vh" : "20vh",
    width: isMobile ? "30vw" : "30vw",
    padding: "8px",
    margin: "4px",
  };

  const sponsors = [
    {
      id: 1,
      name: "Мания",
      details: "Aвто-мото части",
      logo: maniaLogo,
      website: "https://www.facebook.com/profile.php?id=100063713250253",
    },
    {
      id: 2,
      name: "Рекламирай",
      details: "Детаили",
      logo: "sponsor2.png",
      website: "https://www.sponsor2.com",
    },
    {
      id: 3,
      name: "Рекламирай",
      details: "Детаили",
      logo: "sponsor2.png",
      website: "https://www.sponsor2.com",
    },
  ];

  return (
    <div
      className="sponsors"
      style={{ height: "100vh", display: "flex", flexDirection: "column" }}
    >
      <AppDrawer />
      <div className="content" style={{ flex: "auto" }}>
        <List
          sx={{
            display: "flex",
            justifyContent: "center",
            background: "#fcfcfc",
          }}
        >
          {sponsors.map((sponsor, i) => (
            <ul key={i} style={{ listStyle: "none", display: "block", padding: "0" }}>
              <Typography
                sx={{ fontSize: "calc(5px + 2vh)" }}
                color="text.primary"
                gutterBottom
              >
                <b>{sponsor.name}</b>
              </Typography>
              <Typography
                sx={{ fontSize: "calc(10px + 1vh)" }}
                color="text.secondary"
                gutterBottom
              >
                {sponsor.details}
              </Typography>
              <li>
                <a href={sponsor.website}>
                  <img
                    src={sponsor.logo}
                    alt={sponsor.name}
                    style={blockStyle}
                  ></img>
                </a>
              </li>
            </ul>
          ))}
        </List>
      </div>
      <Footer />
    </div>
  );
}

export default Friends;
