import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  List,
  Snackbar,
  Alert,
  Drawer,
  ListItemButton,
  ListItemIcon,
  ListItemText as MUIText,
  Collapse,
  CircularProgress,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PeopleIcon from "@mui/icons-material/People";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import EventIcon from "@mui/icons-material/Event";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import AdminPanelContent from "./ApanelRenderContent";
import CreateContentForm from "./ApanelCreateContent";
import {
  getUsers,
  getAllNews,
  addNews,
  deleteNews,
  getAllEvents,
  addEvent,
  deleteEvent,
} from "./Services";
import Notification from "../../common/Notification";

const areEqual = (prevProps, nextProps) => {
  // Only re-render if `data` or `header` changes.
  return JSON.stringify(prevProps.open) === JSON.stringify(nextProps.open);
};

const AdminPanelDialog = React.memo(({ open, onClose, adminName }) => {
  const [users, setUsers] = useState([]);
  const [news, setNews] = useState([]);
  const [events, setEvents] = useState([]);
  const [shopItems, setShopItems] = useState([]);
  const [selectedSection, setSelectedSection] = useState("news");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [openNews, setOpenNews] = useState(false);
  const [openEvents, setOpenEvents] = useState(false);
  const [openShop, setOpenShop] = useState(false);
  const [openUsers, setOpenUsers] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [alert, setAlert] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(true); // Default drawer open
  const [fetchUpdatedData, setFetchUpdateData] = useState(false);
  const createNewsFormData = useMemo(() => {
    return [
      {
        name: "title",
        label: "Заглавие",
        type: "text",
        placeholder: "Въведете заглавие на новината",
        required: true,
      },
      {
        name: "description",
        label: "Подробности",
        type: "textarea",
        placeholder: "Въведете подробно описание на новината",
        required: false,
      },
      {
        name: "link",
        label: "Link към FB",
        type: "url",
        placeholder: "Въведете линк към новината",
        required: true,
      },
      {
        name: "date",
        label: "Date",
        type: "Date",
        placeholder: "Дата на събитието",
        required: true,
      },
    ];
  }, []);

  const createEventFormData = useMemo(() => {
    return [
      {
        name: "name",
        label: "Заглавие",
        type: "text",
        placeholder: "Въведи заглавие на събитието",
        required: true,
      },
      {
        name: "description",
        label: "Описание",
        type: "textarea",
        placeholder: "Подробности за събитието",
        required: false,
      },
      {
        name: "link",
        label: "Link",
        type: "url",
        placeholder: "Линк към събитието в FB",
        required: true,
      },
      {
        name: "date",
        label: "Date",
        type: "Date",
        placeholder: "Дата на събитието",
        required: true,
      },
    ];
  }, []);
  useEffect(() => {
    setShopItems([]);
    setLoading(false);
    setAlert(false);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setSnackbarMessage(null);
      setSnackbarSeverity("success");
    }, 5000);
    return () => clearTimeout(timer);
  }, [snackbarMessage]);

  const getAllUsers = async () => {
    const users = await getUsers();
    if (users.onSuccess) {
      setUsers(users.onSuccess);
    } else {
      setError(users);
    }
  };

  const getNews = async () => {
    const news = await getAllNews();
    if (news.onSuccess) {
      setNews(news.onSuccess);
    } else {
      setError(news);
    }
  };

  const getEvents = async () => {
    const events = await getAllEvents();
    if (events.onSuccess) {
      setEvents(events.onSuccess);
    } else {
      setError(events);
    }
  };

  useEffect(() => {
    if (open) {
      getAllUsers();
      getNews();
      getEvents();
    }
  }, [open, selectedSection, fetchUpdatedData]);

  const handleSnackbarClose = useCallback(() => {
    setSnackbarOpen(false);
  }, []);

  const handleCreateNew = async (item) => {
    switch (selectedSection) {
      case "createNews":
        try {
          const result = await addNews(item); // Assuming deleteNews is an async function
          if (result.success) {
            setSnackbarMessage("Успешна операция.");
            setFetchUpdateData(!fetchUpdatedData);
          } else {
            setSnackbarSeverity("error");
            setSnackbarMessage("Неуспешна операция");
          }
        } catch (error) {
          setSnackbarSeverity("error");
          setSnackbarMessage("Грешка. Неуспешно запазване.");
        }
        break;
      case "createEvent":
        try {
          const result = await addEvent(item); // Assuming deleteNews is an async function
          if (result.success) {
            setSnackbarMessage("Успешна операция.");
            setFetchUpdateData(!fetchUpdatedData);
          } else {
            setSnackbarSeverity("error");
            setSnackbarMessage("Неуспешна операция");
          }
        } catch (error) {
          setSnackbarSeverity("error");
          setSnackbarMessage("Грешка. Неуспешно запазване.");
        }
        break;
      default:
        setSnackbarSeverity("error");
        setSnackbarMessage("Невалидна опция.");
        break;
    }
  };

  const handleDelete = async (id) => {
    switch (selectedSection) {
      case "news":
        try {
          const result = await deleteNews(id); // Assuming deleteNews is an async function
          if (result.success) {
            // Adjust this based on your deleteNews response
            setSnackbarMessage("Успешна операция.");
            setFetchUpdateData(!fetchUpdatedData);
          } else {
            setSnackbarSeverity("error");
            setSnackbarMessage("Неуспешна операция");
          }
        } catch (error) {
          setSnackbarSeverity("error");
          setSnackbarMessage("Грешка. Неуспешно изтриване.");
        }
        break;
      case "events":
        try {
          const result = await deleteEvent(id); // Assuming deleteNews is an async function
          if (result.success) {
            // Adjust this based on your deleteNews response
            setSnackbarMessage("Успешна операция.");
            setFetchUpdateData(!fetchUpdatedData);
          } else {
            setSnackbarSeverity("error");
            setSnackbarMessage("Неуспешна операция");
          }
        } catch (error) {
          setSnackbarSeverity("error");
          setSnackbarMessage("Грешка. Неуспешно изтриване.");
        }
        break;
      default:
        setSnackbarSeverity("error");
        setSnackbarMessage("Невалидна опция.");
        break;
    }
  };

  // Memoized renderContent function to prevent unnecessary re-renders
  const renderContent = useMemo(() => {
    switch (selectedSection) {
      case "news":
        return (
          <AdminPanelContent
            header="Новини"
            data={news}
            //onSave={handleSave}
            onDelete={handleDelete}
          />
        );
      case "events":
        return (
          <AdminPanelContent
            header="Събития"
            data={events}
            //onSave={handleSave}
            onDelete={handleDelete}
          />
        );
      case "shop":
        return (
          <AdminPanelContent
            header="Магазин"
            data={shopItems}
            //onSave={handleSave}
            onDelete={handleDelete}
          />
        );
      case "users":
        return (
          <AdminPanelContent
            enableEditing={false}
            enableDeleting={false}
            header="Потребители"
            data={users}
            //onSave={handleSave}
            onDelete={handleDelete}
          />
        );
      case "createNews":
        return (
          <CreateContentForm
            header="Създай Новина"
            fields={createNewsFormData}
            onSave={handleCreateNew}
          />
        );
      case "createEvent":
        return (
          <CreateContentForm
            header="Създай Събитие"
            fields={createEventFormData}
            onSave={handleCreateNew}
          />
        );
      case "createShopItem":
        return (
          <CreateContentForm
            header={"Създай артикул"}
            onSave={handleCreateNew}
          />
        );
      default:
        return null;
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedSection,
    news,
    events,
    shopItems,
    users,
    createEventFormData,
    createNewsFormData,
  ]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      sx={{
        "& .MuiDialog-paper": {
          height: "100%",
          margin: 0,
          padding: 0,
          overflow: "hidden", // To avoid horizontal scroll
        },
      }}
    >
      <Notification props={error || alert} />
      <DialogTitle>
        {adminName ? `${adminName}'s Admin Panel` : "Admin Panel"}
        {/* Close Button */}
        <IconButton
          edge="end"
          onClick={onClose}
          color="inherit"
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            zIndex: 1,
          }}
        >
          X
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ display: "flex", height: "100%" }}>
        {/* Sidebar (Drawer for sections) */}
        <Drawer
          sx={{
            "& .MuiDrawer-paper": {
              height: "100%",
              transition: "width 0.3s ease",
            },
          }}
          variant="persistent"
          open={drawerOpen}
        >
          <List>
            <ListItemButton onClick={() => setOpenUsers(!openUsers)}>
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <MUIText>Потребители</MUIText>
            </ListItemButton>
            <Collapse in={openUsers} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton onClick={() => setSelectedSection("users")}>
                  <MUIText sx={{ pl: 4 }}>Преглед потребители</MUIText>
                </ListItemButton>
              </List>
            </Collapse>
            <ListItemButton onClick={() => setOpenNews(!openNews)}>
              <ListItemIcon>
                <LibraryBooksIcon />
              </ListItemIcon>
              <MUIText>Новини</MUIText>
            </ListItemButton>
            <Collapse in={openNews} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton onClick={() => setSelectedSection("news")}>
                  <MUIText sx={{ pl: 4 }}>Преглед новини</MUIText>
                </ListItemButton>
                <ListItemButton
                  onClick={() => setSelectedSection("createNews")}
                >
                  <MUIText sx={{ pl: 4 }}>Създай новина</MUIText>
                </ListItemButton>
              </List>
            </Collapse>
            <ListItemButton onClick={() => setOpenEvents(!openEvents)}>
              <ListItemIcon>
                <EventIcon />
              </ListItemIcon>
              <MUIText>Събития</MUIText>
            </ListItemButton>
            <Collapse in={openEvents} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton onClick={() => setSelectedSection("events")}>
                  <MUIText sx={{ pl: 4 }}>Преглед събития</MUIText>
                </ListItemButton>
                <ListItemButton
                  onClick={() => setSelectedSection("createEvent")}
                >
                  <MUIText sx={{ pl: 4 }}>Създай събитие</MUIText>
                </ListItemButton>
              </List>
            </Collapse>
            <ListItemButton onClick={() => setOpenShop(!openShop)}>
              <ListItemIcon>
                <ShoppingBasketIcon />
              </ListItemIcon>
              <MUIText>Магазин</MUIText>
            </ListItemButton>
            <Collapse in={openShop} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton onClick={() => setSelectedSection("shop")}>
                  <MUIText sx={{ pl: 4 }}>Преглед артикули</MUIText>
                </ListItemButton>
                <ListItemButton
                  onClick={() => setSelectedSection("createShopItem")}
                >
                  <MUIText sx={{ pl: 4 }}>Създай артикул</MUIText>
                </ListItemButton>
              </List>
            </Collapse>
          </List>
        </Drawer>

        {/* Main Content Area */}
        <Box
          sx={{
            flexGrow: 1,
            paddingLeft: 2,
            paddingTop: 2,
            overflowY: "hidden",
          }}
        >
          {snackbarMessage && (
            <Alert severity={snackbarSeverity}>{snackbarMessage}</Alert>
          )}
          {loading ? <CircularProgress /> : renderContent}
        </Box>
      </DialogContent>
      {/* Snackbar Notification */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      ></Snackbar>
      <IconButton
        onClick={() => setDrawerOpen(!drawerOpen)}
        sx={{
          bottom: 80, // Move the button more up
          left: drawerOpen ? "240px" : "0", // Moves the button outside when expanded
          backgroundColor: "grey", // Button color is grey
          color: "#fff",
          width: 36,
          height: 36,
          "&:hover": {
            backgroundColor: "orange", // On hover it turns orange
          },
        }}
      >
        {drawerOpen ? <ArrowBackIcon /> : <ArrowForwardIcon />}
      </IconButton>
    </Dialog>
  );
}, areEqual);

export default AdminPanelDialog;
